import React, { useState, useEffect } from "react";
import Header from "../../../../commons/header/header";
import Footer from "../../../../commons/footer/footer";
import {
  Paper,
  Tabs,
  Tab,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./it.css"; // Ensure you have a CSS file for styling
import image3 from "../../../../../assests/images/it-bg.jpg";

// TabPanel component to display content for each tab
const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const It = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="image-section">
        <img src={image3} alt="Department" className="department-image" />
        <div className="overlay-text">B.Tech INFORMATION TECHNOLOGY</div>
      </div>
      <div className="department-details-content">
        <Paper elevation={3} className="department-details-paper">
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="department tabs"
            centered
          >
            <Tab label="About Department" />
            <Tab label="Faculty Details" />
            <Tab label="PEO / PO / PSO" />
            <Tab label="Lab Facilities" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {/* About Department content */}

            <Typography variant="h6" gutterBottom>
              About Information Technology Department
            </Typography>
            <p>
              The Department has a team of dedicated faculty members with a
              sound knowledge in various areas and it has the state-of-art
              facilities for various laboratories and research fields.
              Information Technology builds a solid foundation in software and
              hardware through absolute teaching and current industrial
              needs.Our department empowers students with the tools and ideas
              that can be applied anywhere in this competitive world. We aim at
              moulding the students into highly qualified expert and to make
              them good citizens of our Nation to serve the industry and
              society. Training is provided periodically by experts to enhance
              the knowledge in Communication, Verbal and Reasoning Skills,
              Analytical Skills and Aptitude Skills of the students to face the
              challenging needs of industry. The students are motivated to take
              part in co-curricular activities, research and to guide them in
              organizing guest lectures, seminars, competitions etc. We
              organized many industrial visits to improve the students knowledge
              in the recent emerging technologies. The dynamic staff members
              published research papers in various journals every academic year.
              The department has a Centre of Excellence with CISCO and signed
              MOUs with many companies. Placement training department have been
              organising classes on Communication, Personality Development and
              Interview Skills program during this academic year.
            </p>
            {/* <Typography variant="h6" gutterBottom>
              HOD: Mrs R. SUJITHA
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              <strong>Assistant Professor &amp; Head</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              Greetings from the Department of Information Technology at Christ The King Engineering College. It gives me immense pleasure to lead the Department of Information Technology which is striving towards the goal of providing innovative and quality education to the students to achieve academic excellence.
            </Typography>
            <Typography variant="body1" paragraph>
              The motto of the department is to provide quality technical education to make the students industry-ready. Our goal is to ensure that our engineering graduates are well prepared to play the roles of problem solvers, project leaders, entrepreneurs, and above all ethical citizens of a global society.
            </Typography>
            <Typography variant="body1" paragraph>
              The excellent infrastructure, dynamic teaching staff along with training and placement cell ensure a bright future for the students. The greatest asset of the department is its highly motivated and learned faculty.
            </Typography>
            <Typography variant="body1" paragraph>
              I am very confident that our students will emerge as assets not only to the institution and to the organization they join, but to society at large. With this, I would like to congratulate the students and staff for their brilliant achievements and wish them a great future.
            </Typography> */}
            <Typography variant="h6" gutterBottom>
              Vision
            </Typography>
            <Typography variant="body1" paragraph>
            To achieve success as a capable to support business and the country's socioeconomic development in the area.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Mission
            </Typography>
            

            <ul>
              <li>
                <Typography variant="body1" paragraph>
                To encourage students to pursue professions in business, academic, and research by fostering computing skills with a focus on professional competency, interpersonal development, and ethics. </Typography>
              </li>
            </ul>

            
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                Enhancing student’s abilities and character to fulfil industry demands and tackle global issues. </Typography>
              </li>
            </ul>
            

            
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                Fostering ongoing relationships with business to develop student’s entrepreneurial abilities and reap reciprocal advantages </Typography>
              </li>
            </ul>
            
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {/* Faculty Details content */}
            <Typography variant="h6" gutterBottom>
              Faculty Details
            </Typography>
            <TableContainer className="table-container">
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-head-cell">S.No.</TableCell>
                    <TableCell className="table-head-cell">
                      Name of the Members
                    </TableCell>
                    <TableCell className="table-head-cell">
                      Designation
                    </TableCell>
                    <TableCell className="table-head-cell">
                      Qualification
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">1</TableCell>
                    <TableCell className="table-cell">Dr. Aejitha</TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc., M.Sc., M.Phil., Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">2</TableCell>
                    <TableCell className="table-cell">
                      Dr. Ramakrishnan A
                    </TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc., M.Sc., Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">3</TableCell>
                    <TableCell className="table-cell">
                      Dr. Russiarani S
                    </TableCell>
                    <TableCell className="table-cell">Professor</TableCell>
                    <TableCell className="table-cell">
                      B.Sc., M.Sc., M.Phil., Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">4</TableCell>
                    <TableCell className="table-cell">Ms. Aparna A V</TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">B.A., M.A</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Typography variant="h6" gutterBottom>
              Program Educational Objectives (PEOs)
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PEO1</TableCell>
                  <TableCell>
                    To provide the students with a strong foundation in the
                    required sciences in order to pursue studies in Electronics
                    and Communication Engineering.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PEO2</TableCell>
                  <TableCell>
                    To gain adequate knowledge to become good professionals in
                    electronic and communication engineering associated
                    industries, higher education, and research.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom>
              Program Outcomes (POs)
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PO1</TableCell>
                  <TableCell>
                    Apply the knowledge of mathematics, science, engineering
                    fundamentals, and an engineering specialization to the
                    solution of complex engineering problems.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO2</TableCell>
                  <TableCell>
                    Identify, formulate, review research literature, and analyze
                    complex engineering problems reaching substantiated
                    conclusions using first principles of mathematics, natural
                    sciences, and engineering sciences.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom>
              Program Specific Outcomes (PSOs)
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PSO1</TableCell>
                  <TableCell>
                    Design, develop, and analyze electronic systems through the
                    application of relevant electronics, mathematics, and
                    engineering principles.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {/* Lab Facilities content */}
            <Typography variant="h6" gutterBottom>
              Lab Facilities
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>1. Data Structures Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The Data Structure lab is precise and equipped with high-end Wipro
              and Lenovo desktop machines for data structure laboratory. This
              lab has 34 Intel Core i5 processor machines and Pentium dual-core
              processor machines with 8 GB RAM, 500 HDD, Windows 10 Pro OS,
              exclusively dedicated for faculty members and students to take up
              research and strengthen their knowledge in data structure.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>2. Programming Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The lab is designed to enhance the programming proficiency of
              students in languages such as C, Python++, JAVA, and web
              technology utilizing IDEs like Sublime, Visual Basic, and
              Notepad++.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>3. Mobile Application Development</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              Our Mobile Application Development Lab, outfitted with Intel Core
              i5 desktop PCs, stands as a vital resource within our facilities.
              It acts as a central hub where students can enhance their skills
              in diverse programming languages like C, C++, Java, Python, and
              database management systems. With 8GB of RAM, the lab is optimized
              for Android application development. Additionally, it accommodates
              various hypervisors such as Oracle Virtual Box, Xen, and
              OpenStack. The lab's infrastructure seamlessly integrates
              high-speed internet connectivity, ensuring students have
              uninterrupted access to online resources.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>4. Data Science & Cloud Computing Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The Data Science and Cloud Computing lab is equipped with Samsung
              & Wipro HP desktop machines, designed specifically for data
              analytics and cloud projects. It houses 31 Wipro Intel i5
              machines, each with 16 GB RAM & 5 Samsung, exclusively for faculty
              and student research in Data Science. The lab provides hands-on
              experience, supports international certifications, and helps
              students secure industry jobs.
            </Typography>
          </TabPanel>
        </Paper>
      </div>
      <Footer />
    </>
  );
};

export default It;
