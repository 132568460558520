import React, { useState } from "react";
import Header from "../../../commons/header/header";
import Footer from "../../../commons/footer/footer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
} from "@mui/material";
import "../committee.css"; // Import the CSS file

const GRC = () => {
  const [selectedTab, setSelectedTab] = useState("committee");

  const rows = [
    {
      id: 1,
      name: "Dr. Eswaramoorthi P",
      designation: "Principal",
      mobile: "7708069402",
      email: "acetcoimbatore@gmail.com",
      address:
        "5, 6 Perumal Kovil Street, Saravanampatti, Coimbatore - 641 035",
    },
    {
      id: 2,
      name: "Mr. Govindharajan V",
      designation: "President / Kondayampalayam",
      mobile: "9994508888",
      email: "govindrevarthi@gmail.com",
      address:
        "Villagepanchayat office, Kondayampalayam, Vaiyampalayam (Po), Coimbatore - 641104",
    },
    {
      id: 3,
      name: "Mr. Durairaj N",
      designation: "Assistant Professor",
      mobile: "8903536371",
      email: "durairajnagaraj@gmail.com",
      address: "59/1 School Street, Edayarpalayam, Coimbatore - 641 025",
    },
    {
      id: 4,
      name: "Ms. Menaka K",
      designation: "Assistant Professor",
      mobile: "9600214561",
      email: "mmenaka1096@gmail.com",
      address:
        "180, SS Garden, Vasantham Nagar, Saravanampatti Post, Coimbatore - 641035",
    },
    {
      id: 5,
      name: "Ms. Geetha S",
      designation: "Assistant Professor",
      mobile: "9944743500",
      email: "geethalive96@gmail.com",
      address:
        "48 A, Annamalai Nagar, Kariyampalayam Post, Annur, Coimbatore - 641653",
    },
    {
      id: 6,
      name: "Mr. Naveen N",
      designation: "III CSE Student",
      mobile: "7603843237",
      email: "naveenpt2004@gmail.com",
      address: "North Street, T Subblapuram, Aundipatty, Theni",
    },
    {
      id: 7,
      name: "Mr. Aathithyan R",
      designation: "II Agri Student",
      mobile: "8946082251",
      email: "aathithyana21@gmail.com",
      address: "953 A, Saluvan Street, Mangadu, Pudukkottai",
    },
    {
      id: 8,
      name: "Miss. Susmitha S",
      designation: "II AIDS Student",
      mobile: "9360030385",
      email: "ssusi552484@gmail.com",
      address: "Jeyankondan, Karikudi, Sivagangai - 630108",
    },
  ];

  const handlePDFClick = () => {
    window.open(
      `${process.env.PUBLIC_URL}/pdf/grc_minutes_of_meeting.pdf`,
      "_blank"
    );
  };
  return (
    <>
      <Header />
      <div className="antiragging-container">
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          width="100%"
        >
          {/* Left side menu */}
          <Box
            flex={{ xs: "none", sm: "0 0 200px" }}
            bgcolor="transparent"
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap={1} // Add spacing between buttons
            sx={{
              // Responsive styling
              "@media (max-width: 600px)": {
                flex: "0 0 auto",
              },
              "@media (min-width: 600px)": {
                flex: "0 0 200px",
              },
            }}
          >
            <Button
              variant={selectedTab === "committee" ? "contained" : "outlined"}
              onClick={() => setSelectedTab("committee")}
              style={{
                width: "100%",
                color: selectedTab === "committee" ? "#004d40" : "#000",
                borderColor:
                  selectedTab === "committee" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              Grievance Redressal Committee
            </Button>
            <Button
              variant={selectedTab === "mom" ? "contained" : "outlined"}
              onClick={handlePDFClick}
              style={{
                width: "100%",
                color: selectedTab === "mom" ? "#004d40" : "#000",
                borderColor: selectedTab === "mom" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              Minutes Of Meeting
            </Button>
          </Box>

          {/* Right side content */}
          <Box flex="1" p={2}>
            <h2>
              {selectedTab === "committee"
                ? "Grievance Redressal Committee"
                : "mom"}
            </h2>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      Name of the Members
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      Designation
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      Category
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      Address
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(selectedTab === "committee"
                    ? rows
                    : selectedTab === "mom"
                  ).map((row, index) => (
                    <TableRow
                      key={row.id}
                      style={{
                        backgroundColor: index % 2 === 0 ? "white" : "#f1f8e9",
                      }}
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.designation}</TableCell>
                      <TableCell>{row.mobile}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.address}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default GRC;
