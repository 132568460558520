import React from 'react';
import { Carousel } from 'react-bootstrap';
import './header.css'; // Import CSS for styling
import image1 from "../../../assests/images/11.png";
import image2 from "../../../assests/images/clg-bg.jpg";
import { Link } from 'react-router-dom';

const Section = () => {
  return (
    <div className='images'>
      <Carousel>
        <Carousel.Item>
          <div className="carousel-caption-custom">
            <h2 className="welcome-text">
             WELCOME TO ASIAN COLLEGE OF
              <br/> ENGINEERING & TECHNOLOGY
            </h2>
            <div className="carousel-buttons">
              <Link className="custom-button" to="/aboutus" >About Us</Link>
              <Link to="/contact" className="custom-button">Contact Us</Link>
            </div>
          </div>
          <img className="d-block w-100" src={image2} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={image1} alt="Second slide" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Section;
