// src/components/stats/Stats.js
import React from "react";
import CountUp from "react-countup";
import "./count.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaChalkboardTeacher,
  FaBook,
  FaUserGraduate,
  FaFlask,
} from "react-icons/fa";

const Stats = () => {
  return (
    <div className="count-box">
      <div className="stats-section">
        <Container>
          <Row>
            <Col xs={6} md={3} className="stat-item">
              <div className="icon-wrapper">
                <FaChalkboardTeacher size={50} className="stat-icon" />
              </div>
              <h3>
                <CountUp end={100} duration={3} />
              </h3>
              <p>Faculty Members</p>
            </Col>
            <Col xs={6} md={3} className="stat-item">
              <div className="icon-wrapper">
                <FaBook size={50} className="stat-icon" />
              </div>
              <h3>
                <CountUp end={25} duration={3} />
              </h3>
              <p>Courses Offered</p>
            </Col>
            <Col xs={6} md={3} className="stat-item">
              <div className="icon-wrapper">
                <FaUserGraduate size={50} className="stat-icon" />
              </div>
              <h3>
                <CountUp end={260} duration={3} />
              </h3>
              <p>Placed Students</p>
            </Col>
            <Col xs={6} md={3} className="stat-item">
              <div className="icon-wrapper">
                <FaFlask size={50} className="stat-icon" />
              </div>
              <h3>
                <CountUp end={25} duration={3} />
              </h3>
              <p>Laboratories</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Stats;
