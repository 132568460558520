import React from "react";
import Header from "../../commons/header/header";
import Footer from "../../commons/footer/footer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import "./placement.css"; // Ensure you have a CSS file for styling
import image3 from "../../../assests/images/agri-bg.jpg";

const Placement = () => {
  return (
    <>
      <Header />
      <div className="image-section">
        <img src={image3} alt="Gallery" className="department-image" />
        <div className="overlay-text">Placement</div>
      </div>
      <div className="placement-container">

      <div className="content-section">
        <h2>Over 100 Recruiter</h2>
        <p>
          The Training and Placement Cell is an imperative division of the
          institution. The cell is projecting towards assisting the students at
          every rung of advancement towards their career. The professional
          objective of the students is detailed and the vocation facet of the
          student is made factual by following a two divisional progression:
          Training and Placement. The series of training afford cosmic openings
          for massive growth of the student’s perspective in the industrial
          scenario.
        </p>
        <h3>Training Division :</h3>
        <p>
          Exhaustive training is provided in the areas of communication,
          aptitude and soft skills to mould the students meet the global
          standards and emerge as employable professionals. A team of In-house
          trainers administer these programmes for the students from the second
          year of their study, on a continuous learning pedagogy. In addition,
          the language laboratory is equipped with the latest multimedia
          facilities for improving the communication skills of the students. For
          making the cell more potent, it is assisted by student placement
          coordinators from all the departments, who lead the team of students
          for placement.
        </p>
      </div>
      </div>
      <Footer />
    </>
  );
};

export default Placement;
