import React, { useState, useEffect } from "react";
import Header from "../../../../commons/header/header";
import Footer from "../../../../commons/footer/footer";
import {
  Paper,
  Tabs,
  Tab,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./eee.css"; // Ensure you have a CSS file for styling
import image3 from "../../../../../assests/images/eee-bg.jpg";

// TabPanel component to display content for each tab
const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Eee = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="image-section">
        <img src={image3} alt="Department" className="department-image" />
        <div className="overlay-text">
          B.E. – ELECTRICAL AND ELECTRONICS ENGINEERING
        </div>
      </div>
      <div className="department-details-content">
        <Paper elevation={3} className="department-details-paper">
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="department tabs"
            centered
          >
            <Tab label="About Department" />
            <Tab label="Faculty Details" />
            <Tab label="PEO / PO / PSO" />
            <Tab label="Lab Facilities" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {/* About Department content */}

            <Typography variant="h6" gutterBottom>
              About Electrical and Electronics Engineering Department
            </Typography>
            <p>
              Electrical and Electronics Engineering (EEE) is a field of
              engineering that generally deals with the study and application of
              electricity, electronics, and electro magnetism. A degree in
              Electrical and Electronic Engineering prepares one to work in the
              vast range of engineering sectors, including Aerospace,
              Automotive, Power generation, Renewable energy, Information
              technology and Telecommunications.
            </p>
            <p>
              Asian offers a four year undergraduate program in Electrical and
              Electronics Engineering with a sanctioned intake of 60 students.
              It is a subject that never ceases to fascinate and is a challenge
              for the constant evolution it represents. With the emergence of
              newer and more advances systems, computer science education has
              become more comprehensive than ever. The goal of the department is
              to create engineers of high technical skills in the Electrical &
              Electronics Engineering field suitable for employment in reputed
              firms and for higher studies. The department caters to the ever
              increasing needs of technical brilliance in all areas of
              electrical and electronics engineering such as integrated
              electronics and circuits, electrical machines, control &
              automation, power systems & power electronics. at asian, the
              department of EEE, comprising technically-qualified staff and
              state-of-the art laboratories, continuously strives to equip the
              students for a career that truly clicks!.
            </p>
            {/* <Typography variant="h6" gutterBottom>
              HOD: Mrs R. SUJITHA
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              <strong>Assistant Professor &amp; Head</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              Greetings from the Department of Information Technology at Christ
              The King Engineering College. It gives me immense pleasure to lead
              the Department of Information Technology which is striving towards
              the goal of providing innovative and quality education to the
              students to achieve academic excellence.
            </Typography>
            <Typography variant="body1" paragraph>
              The motto of the department is to provide quality technical
              education to make the students industry-ready. Our goal is to
              ensure that our engineering graduates are well prepared to play
              the roles of problem solvers, project leaders, entrepreneurs, and
              above all ethical citizens of a global society.
            </Typography>
            <Typography variant="body1" paragraph>
              The excellent infrastructure, dynamic teaching staff along with
              training and placement cell ensure a bright future for the
              students. The greatest asset of the department is its highly
              motivated and learned faculty.
            </Typography>
            <Typography variant="body1" paragraph>
              I am very confident that our students will emerge as assets not
              only to the institution and to the organization they join, but to
              society at large. With this, I would like to congratulate the
              students and staff for their brilliant achievements and wish them
              a great future.
            </Typography> */}
            {/* <Typography variant="h6" gutterBottom>
              Vision
            </Typography>
            <Typography variant="body1" paragraph>
              IT department at Christ The King Engineering College is to be the
              cornerstone of technological innovation and excellence, empowering
              the college community to excel in teaching, learning, research,
              and administration. We envision an IT department that serves as a
              catalyst for academic success, fosters a culture of innovation,
              and enhances the overall educational experience for students,
              faculty, and staff.
            </Typography> */}
            <Typography variant="h6" gutterBottom>
              Vision
            </Typography>
            <Typography variant="body1" paragraph>
            To provide candidates with knowledge and skill in the field of EEE and thereby produce extremely well trained employable, socially responsible and innovative Electrical and Electronics Engineers.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Mission
            </Typography>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                To create graduates possessing sound fundamental knowledge of Electrical Engineering.
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                To create engineering manpower for contributing effectively towards societal development.</Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                To create competent and skilled professionals who are well trained to design, implement and control electrical efficient systems.</Typography>
              </li>
            </ul>


            
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {/* Faculty Details content */}
            <Typography variant="h6" gutterBottom>
              Faculty Details
            </Typography>
            <TableContainer className="table-container">
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-head-cell">S.No.</TableCell>
                    <TableCell className="table-head-cell">
                      Name of the Members
                    </TableCell>
                    <TableCell className="table-head-cell">
                      Designation
                    </TableCell>
                    <TableCell className="table-head-cell">
                      Qualification
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">1</TableCell>
                    <TableCell className="table-cell">Dr. Aejitha</TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc., M.Sc., M.Phil., Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">2</TableCell>
                    <TableCell className="table-cell">
                      Dr. Ramakrishnan A
                    </TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc., M.Sc., Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">3</TableCell>
                    <TableCell className="table-cell">
                      Dr. Russiarani S
                    </TableCell>
                    <TableCell className="table-cell">Professor</TableCell>
                    <TableCell className="table-cell">
                      B.Sc., M.Sc., M.Phil., Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">4</TableCell>
                    <TableCell className="table-cell">Ms. Aparna A V</TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">B.A., M.A</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {/* Quality Policy content */}
            <Typography variant="h6" gutterBottom>
            PROGRAM EDUCATIONAL OBJECTIVES (PEOs):
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PEO1</TableCell>
                  <TableCell>
                    Apply knowledge of mathematics, basic science and
                    engineering science
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PEO2</TableCell>
                  <TableCell>
                    Design an electrical system or process to improve its
                    performance, satisfying its constraints.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PEO3</TableCell>
                  <TableCell>
                    Conduct experiments in electrical and electronics systems
                    and interpret the data.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PEO4</TableCell>
                  <TableCell>
                    Conduct themselves to uphold the professional and social
                    obligations.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom>
              PROGRAM OUTCOMES (POs):
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PO1</TableCell>
                  <TableCell>
                    <strong>Engineering knowledge:</strong>Apply the knowledge
                    of mathematics, science, engineering fundamentals, and an
                    engineering specialization to the solution of complex
                    engineering problems.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO2</TableCell>
                  <TableCell>
                    <strong>Problem analysis: </strong> Identify, formulate,
                    review research literature, and analyze complex engineering
                    problems reaching substantiated conclusions using first
                    principles of mathematics, natural sciences, and engineering
                    sciences.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO3</TableCell>
                  <TableCell>
                    <strong>Design/development of solutions:</strong>Design
                    solutions for complex engineering problems and design system
                    components or processes that meet the specified needs with
                    appropriate consideration for the public health and safety,
                    and the cultural, societal, and environmental
                    considerations.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO4</TableCell>
                  <TableCell>
                    <strong>Conduct investigations of complex problems:</strong>{" "}
                    Use research-based knowledge and research methods including
                    design of experiments, analysis and interpretation of data,
                    and synthesis of the information to provide valid
                    conclusions.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO5</TableCell>
                  <TableCell>
                    <strong>Modern tool usage:</strong>Create, select, and apply
                    appropriate techniques, resources, and modern engineering
                    and IT tools including prediction and modeling to complex
                    engineering activities with an understanding of the
                    limitations.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO6</TableCell>
                  <TableCell>
                    <strong>The engineer and society:</strong> Apply reasoning
                    informed by the contextual knowledge to assess societal,
                    health, safety, legal and cultural issues and the consequent
                    responsibilities relevant to the professional engineering
                    practice.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO7</TableCell>
                  <TableCell>
                    <strong>Environment and sustainability:</strong>Understand
                    the impact of the professional engineering solutions in
                    societal and environmental contexts, and demonstrate the
                    knowledge of, and need for sustainable development.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO8</TableCell>
                  <TableCell>
                    <strong>Ethics:</strong>Apply ethical principles and commit
                    to professional ethics and responsibilities and norms of the
                    engineering practice.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO9</TableCell>
                  <TableCell>
                    <strong>Individual and team work:</strong>Function
                    effectively as an individual, and as a member or leader in
                    diverse teams, and in multidisciplinary settings.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO10</TableCell>
                  <TableCell>
                    <strong>Communication:</strong>Communicate effectively on
                    complex engineering activities with the engineering
                    community and with society at large, such as, being able to
                    comprehend and write effective reports and design
                    documentation, make effective presentations, and give and
                    receive clear instructions.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO11</TableCell>
                  <TableCell>
                    <strong>Project management and finance:</strong>Demonstrate
                    knowledge and understanding of the engineering and
                    management principles and apply these to one’s own work, as
                    a member and leader in a team, to manage projects and in
                    multidisciplinary environments.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO12</TableCell>
                  <TableCell>
                    <strong>Life-long learning:</strong>Recognize the need for,
                    and have the preparation and ability to engage in
                    independent and life-long learning in the broadest context
                    of technological change.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom>
              PROGRAM SPECIFIC OBJECTIVES (PSOs):
            </Typography>
            <Table>
              <Typography>
                On completion of Electrical and Electronics Engineering program,
                the student will have the following Program Specific Outcomes.
              </Typography>

              <ol>
                <li>
                  <strong>Foundation of Electrical Engineering: </strong>Ability
                  to understand the principles and working of electrical
                  components, circuits, systems and control that are forming a
                  part of power generation, transmission, distribution,
                  utilization, conservation and energy saving. Students can
                  assess the power management, auditing, crisis and energy
                  saving aspects.
                </li>
                <li>
                  <strong>Foundation of Mathematical Concepts:</strong> Ability
                  to apply mathematical methodologies to solve problems related
                  with electrical engineering using appropriate engineering
                  tools and algorithms.
                </li>
                <li>
                  <strong>Computing and Research Ability:</strong> Ability to
                  use knowledge in various domains to identify research gaps and
                  hence to provide solution which leads to new ideas and
                  innovations.
                </li>
              </ol>
            </Table>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {/* Lab Facilities content */}
            <Typography variant="h6" gutterBottom>
              Lab Facilities
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>1. Data Structures Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The Data Structure lab is precise and equipped with high-end Wipro
              and Lenovo desktop machines for data structure laboratory. This
              lab has 34 Intel Core i5 processor machines and Pentium dual-core
              processor machines with 8 GB RAM, 500 HDD, Windows 10 Pro OS,
              exclusively dedicated for faculty members and students to take up
              research and strengthen their knowledge in data structure.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>2. Programming Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The lab is designed to enhance the programming proficiency of
              students in languages such as C, Python++, JAVA, and web
              technology utilizing IDEs like Sublime, Visual Basic, and
              Notepad++.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>3. Mobile Application Development</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              Our Mobile Application Development Lab, outfitted with Intel Core
              i5 desktop PCs, stands as a vital resource within our facilities.
              It acts as a central hub where students can enhance their skills
              in diverse programming languages like C, C++, Java, Python, and
              database management systems. With 8GB of RAM, the lab is optimized
              for Android application development. Additionally, it accommodates
              various hypervisors such as Oracle Virtual Box, Xen, and
              OpenStack. The lab's infrastructure seamlessly integrates
              high-speed internet connectivity, ensuring students have
              uninterrupted access to online resources.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>4. Data Science & Cloud Computing Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The Data Science and Cloud Computing lab is equipped with Samsung
              & Wipro HP desktop machines, designed specifically for data
              analytics and cloud projects. It houses 31 Wipro Intel i5
              machines, each with 16 GB RAM & 5 Samsung, exclusively for faculty
              and student research in Data Science. The lab provides hands-on
              experience, supports international certifications, and helps
              students secure industry jobs.
            </Typography>
          </TabPanel>
        </Paper>
      </div>
      <Footer />
    </>
  );
};

export default Eee;
