import React, { useState } from "react";
import Header from "../../../commons/header/header";
import Footer from "../../../commons/footer/footer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
} from "@mui/material";
import "../committee.css"; // Import the CSS file

const ANTIRAGGING = () => {
  const [selectedTab, setSelectedTab] = useState("committee");

  const committeeRows = [
    { id: 1, name: "Dr. Eswaramoorthi P", position: "Chairman", designation: "Principal", mobile: "7708069402", email: "acetcoimbatore@gmail.com", address: "5, 6 Perumal Kovil Street, Saravanampatti, Coimbatore - 641035" },
    { id: 2, name: "Mr. Gnanasekaran V", position: "Member", designation: "Police Inspector", mobile: "7010229821", email: "kovilpalayamps@yahoo.in", address: "Police Quarters, Police Quarters Road, Kovilpalayam, Coimbatore - 641107" },
    { id: 3, name: "Mr. Sivakumar AV", position: "Member", designation: "Revenue/Taluk/Civil Officer", mobile: "9940880903", email: "thasildharannur@gmail.com", address: "Annur Thasildhar Office, Sathy Main Road, Annur, Coimbatore - 641035" },
    { id: 4, name: "Ms. Padmavathi R", position: "Member", designation: "NGO", mobile: "9043883964", email: "padmavathimmampalayam@gmail.com", address: "9/281, Thimmampalayam, Karamadai, Coimbatore - 641104" },
    { id: 5, name: "Mrs. Maheshwari S", position: "Member", designation: "Assistant Professor", mobile: "9952892162", email: "smaheshw22@gmail.com", address: "7/158, Kottaipalayam, Coimbatore - 641110" },
    { id: 6, name: "Mr. Praveen MS", position: "Member", designation: "Assistant Professor", mobile: "9629132432", email: "mecpraveen@gmail.com", address: "2-6 MS Bhavan, Kulathuvilagam, Kanyakumari - 629804" },
    { id: 7, name: "Mrs. Mageshwari K", position: "Member", designation: "Representatives of Parents", mobile: "8825537414", email: "kharishmagesh@gmail.com", address: "4/1 Ponniyamman Kovil Street, Nachiyarkovil, Thanjavur - 612602" },
    { id: 8, name: "Mr. Suthi B", position: "Member", designation: "Representatives of Students", mobile: "8610550036", email: "suthisuthi009@gmail.com", address: "Sheikkal Mudi, Puthukkadu, Valparai, Coimbatore - 642127" },
    { id: 9, name: "Ms. Logadharshini R", position: "Member", designation: "Representatives of Students", mobile: "7010928509", email: "yogadharshiniyogadharshini057@gmail.com", address: "3/407 Ammanpudur, Pethikuttai, Coimbatore - 638459" },
    { id: 10, name: "Mr. Senthilkumar E", position: "Member", designation: "Lab Technician", mobile: "8643814364", email: "senthilleela1964@gmail.com", address: "132 Thudiyalur Main Road, Idikarai, Coimbatore - 641022" }
];

  const squadRows = [
    { id: 1, name: "Dr. Eswaramoorthi P", position: "Chairman", designation: "Principal", mobile: "7708069402", email: "acetcoimbatore@gmail.com", address: "5, 6 Perumal Kovil Street, Saravanampatti, Coimbatore - 641035" },
    { id: 2, name: "Dr. Savithri K.M", position: "Member", designation: "Professor", mobile: "9894582809", email: "kms17grg@gmail.com", address: "4/34 Rathinagirinagar, Vilankuruchi, Saravanamatti Post, Coimbatore - 641035" },
    { id: 3, name: "Mrs. Maheshwari S", position: "Member", designation: "Assistant Professor", mobile: "9952892162", email: "smaheshw22@gmail.com", address: "7/158 Kottaipalayam, Coimbatore - 641110" },
    { id: 4, name: "Mr. Praveen MS", position: "Member", designation: "Assistant Professor", mobile: "9629132432", email: "mecpraveen@gmail.com", address: "2-6 M S Bhavan, Kulathuvilagam, Mathicode-Thickanam Code, Kanyakumari - 629804" },
    { id: 5, name: "Mr. Gowdhaman V", position: "Member", designation: "Assistant Professor", mobile: "8754155999", email: "vsgowdham@gmail.com", address: "42/216 Malaya Patti Post, Rasipuram Taluk, Namakkal - 637407" },
    { id: 6, name: "Dr. Russiarani S", position: "Member", designation: "Assistant Professor", mobile: "9655457326", email: "russiarani1986@gmail.com", address: "Alakkudivia, Thennangudi Post, Thanjavur - 613601" },
    { id: 7, name: "Mr. Vasantha Kumar T", position: "Member", designation: "Assistant Professor", mobile: "9524217710", email: "kumar.vasanth57@gmail.com", address: "1st Block Ganapathy, Coimbatore - 641006" },
    { id: 8, name: "Ms. Vinisha", position: "Member", designation: "Lab Technician", mobile: "9597655893", email: "asokvinisha@gmail.com", address: "430/7 Pattakaranpudur, Mettualayam Post, Annur Taluk, Coimbatore - 641653" }
];

const monitoringCellRows = [
  { id: 1, name: "Dr. Eswaramoorthi P", position: "Chairman", designation: "Principal", mobile: "7708069402" },
  { id: 2, name: "Dr. Savithri K.M", position: "Member", designation: "Professor / Electronics and Communication Engineering", mobile: "9894582809" },
  { id: 3, name: "Dr. Aejitha S", position: "Member", designation: "Associate Professor / Chemistry", mobile: "9865884322" },
  { id: 4, name: "Mr. Senthil Babu D", position: "Member", designation: "Assistant Professor / BME", mobile: "9003555774" },
  { id: 5, name: "Mr. Gowdhaman V", position: "Member", designation: "Associate Professor / Computer Science and Engineering", mobile: "8754155999" },
  { id: 6, name: "Mr. Vasantha Kumar T", position: "Member", designation: "Assistant Professor / Civil Engineering", mobile: "9655457326" }
];

const boysHostelCellRows = [
  { id: 1, name: "Dr. Eswaramoorthi P", position: "Chairman", designation: "Principal", mobile: "7708069402" },
  { id: 2, name: "Mr. Senthil Babu D", position: "Member", designation: "Assistant Professor / BME", mobile: "9003555774" },
  { id: 3, name: "Mr. Gowdhaman V", position: "Member", designation: "Associate Professor / Computer Science and Engineering", mobile: "8754155999" },
  { id: 4, name: "Mr. Vasantha Kumar T", position: "Member", designation: "Assistant Professor / Civil Engineering", mobile: "9655457326" },
  { id: 5, name: "Mr. Vadivel G", position: "Member", designation: "Assistant Professor / CSE / Warden", mobile: "9788411147" }
];

  

const girlsHostelCellRows = [
  { id: 1, name: "Dr. Savithri K.M", position: "Chairman", designation: "Professor / Electronics and Communication Engineering", mobile: "7708069402" },
  { id: 2, name: "Ms. Aparna A.V", position: "Member", designation: "Assistant Professor / Deputy Warden of Girls Hostel", mobile: "8606321721" },
  { id: 3, name: "Ms. Sangeetha S", position: "Member", designation: "Assistant Professor", mobile: "8270603678" }
];


  const handlePDFClick = () => {
    window.open(`${process.env.PUBLIC_URL}/pdf/anti_ragging_minutes_of_meeting.pdf`, "_blank");
  };

  return (
    <>
      <Header />
      <div className="antiragging-container">
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          width="100%"
        >
          {/* Left side menu */}
          <Box
            flex={{ xs: "none", sm: "0 0 200px" }}
            bgcolor="transparent"
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap={1} // Add spacing between buttons
            sx={{
              // Responsive styling
              '@media (max-width: 600px)': {
                flex: '0 0 auto',
              },
              '@media (min-width: 600px)': {
                flex: '0 0 200px',
              },
            }}
          >
            <Button
              variant={selectedTab === "committee" ? "contained" : "outlined"}
              onClick={() => setSelectedTab("committee")}
              style={{
                width: "100%",
                color: selectedTab === "committee" ? "#004d40" : "#000",
                borderColor: selectedTab === "committee" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              Anti - Ragging Committee
            </Button>
            <Button
              variant={selectedTab === "squad" ? "contained" : "outlined"}
              onClick={() => setSelectedTab("squad")}
              style={{
                width: "100%",
                color: selectedTab === "squad" ? "#004d40" : "#000",
                borderColor: selectedTab === "squad" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              Anti-Ragging Squad
            </Button>
            <Button
              variant={selectedTab === "monitoring" ? "contained" : "outlined"}
              onClick={() => setSelectedTab("monitoring")}
              style={{
                width: "100%",
                color: selectedTab === "monitoring" ? "#004d40" : "#000",
                borderColor: selectedTab === "monitoring" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              Monitoring Cell
            </Button>
            <Button
              variant={selectedTab === "boysHostel" ? "contained" : "outlined"}
              onClick={() => setSelectedTab("boysHostel")}
              style={{
                width: "100%",
                color: selectedTab === "boysHostel" ? "#004d40" : "#000",
                borderColor: selectedTab === "boysHostel" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              Boys Hostel Monitoring Cell
            </Button>
            <Button
              variant={selectedTab === "girlsHostel" ? "contained" : "outlined"}
              onClick={() => setSelectedTab("girlsHostel")}
              style={{
                width: "100%",
                color: selectedTab === "girlsHostel" ? "#004d40" : "#000",
                borderColor: selectedTab === "girlsHostel" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              Girls Hostel Monitoring Cell
            </Button>

            <Button
              variant={selectedTab === "mom" ? "contained" : "outlined"}
              onClick={handlePDFClick}
              style={{
                width: "100%",
                color: selectedTab === "mom" ? "#004d40" : "#000",
                borderColor: selectedTab === "mom" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              Minutes Of Meeting
            </Button>


          </Box>

          {/* Right side content */}
          <Box flex="1" p={2}>
            <h2>
              {selectedTab === "committee"
                ? "Anti - Ragging Committee"
                : selectedTab === "squad"
                ? "Anti-Ragging Squad"
                : selectedTab === "monitoring"
                ? "Monitoring Cell"
                : selectedTab === "boysHostel"
                ? "Boys Hostel Monitoring Cell"
                : "Girls Hostel Monitoring Cell"}
            </h2>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#01366b', color: 'white' }}>S.No.</TableCell>
                    <TableCell sx={{ backgroundColor: '#01366b', color: 'white' }}>Name</TableCell>
                    <TableCell sx={{ backgroundColor: '#01366b', color: 'white' }}>Position</TableCell>
                    <TableCell sx={{ backgroundColor: '#01366b', color: 'white' }}>Designation/Occupation</TableCell>
                    <TableCell sx={{ backgroundColor: '#01366b', color: 'white' }}>Mobile Numbers</TableCell>
                    {selectedTab === "committee" && (
                      <>
                        <TableCell sx={{ backgroundColor: '#01366b', color: 'white' }}>Emailid</TableCell>
                        <TableCell sx={{ backgroundColor: '#01366b', color: 'white' }}>Address</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(selectedTab === "committee"
                    ? committeeRows
                    : selectedTab === "squad"
                    ? squadRows
                    : selectedTab === "monitoring"
                    ? monitoringCellRows
                    : selectedTab === "boysHostel"
                    ? boysHostelCellRows
                    : selectedTab === "girlsHostel"
                    ? girlsHostelCellRows
                    : selectedTab === "mom"

                  ).map((row, index) => (
                    <TableRow
                      key={row.id}
                      style={{
                        backgroundColor: index % 2 === 0 ? "white" : "#f1f8e9",
                      }}
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.position}</TableCell>
                      <TableCell>{row.designation}</TableCell>
                      <TableCell>{row.mobile}</TableCell>
                      {selectedTab === "committee" && (
                        <>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.address}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <div className="pdf-link">
              <Link component="button" variant="body2" onClick={handlePDFClick}>
                Minutes of Meeting
              </Link>
            </div> */}
          </Box>
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default ANTIRAGGING;
