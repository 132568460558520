import React, { useState } from "react";
import Header from "../../../commons/header/header";
import Footer from "../../../commons/footer/footer";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, List, ListItem, ListItemText, Typography } from '@mui/material';
import "./faculty.css";

const departments = [
  "Computer Science",
  "Mathematics",
  "Physics",
  // Add more departments as needed
];

const facultyDetails = {
  "Computer Science": [
    { id: 1, name: "Dr. John Doe", designation: "Professor", qualification: "Ph.D." },
    { id: 2, name: "Ms. Jane Smith", designation: "Assistant Professor", qualification: "M.Sc." },
    // Add more faculty members as needed
  ],
  "Mathematics": [
    { id: 1, name: "Dr. Alan Turing", designation: "Professor", qualification: "Ph.D." },
    { id: 2, name: "Ms. Ada Lovelace", designation: "Assistant Professor", qualification: "M.Sc." },
    // Add more faculty members as needed
  ],
  "Physics": [
    { id: 1, name: "Dr. Albert Einstein", designation: "Professor", qualification: "Ph.D." },
    { id: 2, name: "Ms. Marie Curie", designation: "Assistant Professor", qualification: "M.Sc." },
    // Add more faculty members as needed
  ],
  // Add more departments with their faculty details as needed
};

const FacultyProfile = () => {
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  return (
    <div className="faculty-profile-container">
      <Header />

      <div className="content">
        <div className="departments-list">
          <h3>Departments</h3>
          <List>
            {departments.map((department, index) => (
              <ListItem
                key={index}
                button
                selected={selectedDepartment === department}
                onClick={() => setSelectedDepartment(department)}
                className={selectedDepartment === department ? "selected-department" : ""}
              >
                <ListItemText primary={department} />
              </ListItem>
            ))}
          </List>
        </div>

        <div className="faculty-details">
          <h2>Faculty Details</h2>
          {selectedDepartment ? (
            <>
              <h3>{selectedDepartment}</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ backgroundColor: '#01366b', color: 'white' }}>S.No.</TableCell>
                      <TableCell sx={{ backgroundColor: '#01366b', color: 'white' }}>Name of the Members</TableCell>
                      <TableCell sx={{ backgroundColor: '#01366b', color: 'white' }}>Designation</TableCell>
                      <TableCell sx={{ backgroundColor: '#01366b', color: 'white' }}>Qualification</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {facultyDetails[selectedDepartment].map((member, index) => (
                      <TableRow key={member.id} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#e0f7fa' }}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{member.name}</TableCell>
                        <TableCell>{member.designation}</TableCell>
                        <TableCell>{member.qualification}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Typography variant="h6" color="textSecondary">
              Please select a department to view faculty details.
            </Typography>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FacultyProfile;
