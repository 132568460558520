// Infrastructure.js
import React from 'react';
import './infra.css'; // Import CSS file for styling
import Header from '../../commons/header/header';
import Footer from '../../commons/footer/footer';
import image3 from '../../../../src/assests/images/1.jpg';

const images = [
  { src: image3, heading: 'Image 1' },
  { src: image3, heading: 'Image 2' },
  { src: image3, heading: 'Image 3' },
  { src: image3, heading: 'Image 4' },
  { src: image3, heading: 'Image 5' },
  { src: image3, heading: 'Image 6' },
  { src: image3, heading: 'Image 7' },
  { src: image3, heading: 'Image 8' },
  { src: image3, heading: 'Image 9' },
  { src: image3, heading: 'Image 10' },
  { src: image3, heading: 'Image 11' },
  { src: image3, heading: 'Image 12' },
];

const Infrastructure = () => {
  return (
    <>
      <Header />
      <div className="infrastructure-wrapper">
        <h2 className="infrastructure-heading">Our Infrastructure</h2>
        <div className="infrastructure-container">
          {images.map((image, index) => (
            <div key={index} className="image-item">
              <img src={image.src} alt={image.heading} />
              <div className="image-heading">{image.heading}</div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Infrastructure;
