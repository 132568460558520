import React from "react";
import { Link } from "react-router-dom";
import Header from "../../../commons/header/header";
import Footer from "../../../commons/footer/footer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import "./dvv.css"; // Ensure you have a CSS file for styling
import image3 from "../../../../assests/images/agri-bg.jpg";

const DVV = () => {
  return (
    <>
      <Header />
      <div className="image-section">
        <img src={image3} alt="Gallery" className="department-image" />
        <div className="overlay-text">NAAC</div>
      </div>

      {/* Central Box with 2x2 grid */}
      <div className="central-box">
        <div className="grid-container">
          <Link to="https://acetcbe.in/pdf/self-declartion-doc.pdf"  target="_blank"
                rel="noopener noreferrer" className="grid-item">1. Self Declaration</Link>
          <Link to="https://acetcbe.in/pdf/undertaking-doc.pdf"  target="_blank"
                rel="noopener noreferrer" className="grid-item">2. Undertaking</Link>
          <Link to="https://acetcbe.in/pdf/iiqa-doc.pdf"  target="_blank"
                rel="noopener noreferrer" className="grid-item">3. IIQA</Link>
          <Link to="/naac" className="grid-item">4. SSR Metric wise links</Link>
          <Link to="https://acetcbe.in/pdf/draft-ssr-copy-doc.pdf"  target="_blank"
                rel="noopener noreferrer" className="grid-item">5. Draft SSR Copy</Link>
          <Link to="/ssr-dvv-clarifications" className="grid-item">6. SSR - DVV Clarifications</Link>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default DVV;
