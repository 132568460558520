import React, { useState } from "react";
import Header from "../../commons/header/header";
import Footer from "../../commons/footer/footer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import "./naac.css"; // Ensure you have a CSS file for styling
import image3 from "../../../assests/images/agri-bg.jpg";

// const handlePDFClick = () => {
//   window.open(
//     `${process.env.PUBLIC_URL}/pdf/criteria-5/5.1.1/5.1.1.pdf`,
//     "_blank"
//   );
// };

const handleEnrollementClick = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-2/2.1/2.1.1/ENROLMENT-PERCENTAGE.pdf`,
    "_blank"
  );
};

const handle221Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-2/2.2/Students-Teachers-Ratio.pdf`,
    "_blank"
  );
};

const handle212Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-2/2.1/2.1.2/SeatsReserved.pdf`,
    "_blank"
  );
};

const handle231Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-2/2.3/StudentCentricMethods.pdf`,
    "_blank"
  );
};

const handle241Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-2/2.4/2.4.1/FullTimeTeachersSancPost.pdf`,
    "_blank"
  );
};

const handle251Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-2/2.5.1/AssesmentMechanism.pdf`,
    "_blank"
  );
};

const handle242Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-2/2.4/2.4.2/FullTimeTeachers.pdf`,
    "_blank"
  );
};

const handle541Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-5/5.4.1/Index.pdf`,
    "_blank"
  );
};

const handle514Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-5/5.1.4/StudentGrievances.pdf`,
    "_blank"
  );
};

const handle521Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-5/5.2.1/Description.pdf`,
    "_blank"
  );
};

const handle522Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-5/5.2.2/Description.pdf`,
    "_blank"
  );
};

const handle411Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-4/4.1.1/Infrastructure-Physicalfacilities.pdf`,
    "_blank"
  );
};

const handle412Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-4/4.1.2/InfrastructureAugmentation.pdf`,
    "_blank"
  );
};

const handle421Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-4/4.2.1/Description.pdf`,
    "_blank"
  );
};

const handle431Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-4/4.3.1/Description.pdf`,
    "_blank"
  );
};

const handle261Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-2/2.6.1/Description.pdf`,
    "_blank"
  );
};

const handle262Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-2/2.6.2/Description.pdf`,
    "_blank"
  );
};

const handle263Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-2/2.6.3/Description.pdf`,
    "_blank"
  );
};

const handle432Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-4/4.3.2/Description.pdf`,
    "_blank"
  );
};

const handle441Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-4/4.4.1/Description.pdf`,
    "_blank"
  );
};

const handle131Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-1/1.3.1/Description.pdf`,
    "_blank"
  );
};

const handle512Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-5/5.1.2/Description.pdf`,
    "_blank"
  );
};

const handle511Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-5/5.1.1/Description.pdf`,
    "_blank"
  );
};

const handle513Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-5/5.1.3/Description.pdf`,
    "_blank"
  );
};

const handle531Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-5/5.3.1/Description.pdf`,
    "_blank"
  );
};

const handle342Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-3/3.4.2/AwardsandRecognitions.pdf`,
    "_blank"
  );
};

const handle532Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-5/5.3.2/Description.pdf`,
    "_blank"
  );
};

const handle714Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-7/7.1.4/Description.pdf`,
    "_blank"
  );
};

const handle321Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-3/3.2.1/DescriptionMain.pdf`,
    "_blank"
  );
};

const handle311Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-3/3.1.1/GrantsReceived.pdf`,
    "_blank"
  );
};

const handle322Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-3/3.2.2/Description.pdf`,
    "_blank"
  );
};

const handle351Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-3/3.5.1/Description.pdf`,
    "_blank"
  );
};

const handle111Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-1/1.1.1/CurriculumPlanning.pdf`,
    "_blank"
  );
};

const handle611Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-6/6.1.1/GovernanceLeadership.pdf`,
    "_blank"
  );
};

const handle621Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-6/6.2.1/Description.pdf`,
    "_blank"
  );
};

const handle622Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-6/6.2.2/Description.pdf`,
    "_blank"
  );
};

const handle641Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-6/6.4.1/Description.pdf`,
    "_blank"
  );
};

const handle651Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-6/6.5.1/Frontpage.pdf`,
    "_blank"
  );
};

const handle652Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-6/6.5.2/Qualityassuranceinitiatives.pdf`,
    "_blank"
  );
};

const handle631Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-6/6.3.1/Description.pdf`,
    "_blank"
  );
};

const handle632Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-6/6.3.2/Description.pdf`,
    "_blank"
  );
};

const handle633Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-6/6.3.3/Description.pdf`,
    "_blank"
  );
};

const handle331Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-3/3.3.1/Description.pdf`,
    "_blank"
  );
};

const handle332Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-3/3.3.2/Description.pdf`,
    "_blank"
  );
};

const handle121Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-1/1.2.1/Description.pdf`,
    "_blank"
  );
};

const handle132Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-1/1.3.2/Description.pdf`,
    "_blank"
  );
};

const handle713Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-7/7.1.3/Description.pdf`,
    "_blank"
  );
};

const handle711Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-7/7.1.1/Description.pdf`,
    "_blank"
  );
};

const handle712Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-7/7.1.2/Description.pdf`,
    "_blank"
  );
};

const handle731Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-7/7.3.1/Description.pdf`,
    "_blank"
  );
};

const handle721Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-7/7.2.1/Description.pdf`,
    "_blank"
  );
};

const handle141Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-1/1.4.1/FeedbackProcess.pdf`,
    "_blank"
  );
};

const handle343Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-3/3.4.3/Description.pdf`,
    "_blank"
  );
};

const handle341Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/CRITERION-3/3.4.1/Description.pdf`,
    "_blank"
  );
};

const handleEx1Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/EXTENDED-PROFILE/1.1/Description.pdf`,
    "_blank"
  );
};

const handleEx2Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/EXTENDED-PROFILE/2.1/Description.pdf`,
    "_blank"
  );
};

const handleEx3Click = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/EXTENDED-PROFILE/3.1/Description.pdf`,
    "_blank"
  );
};


const NAAC = () => {
  const [activeTab, setActiveTab] = useState("tab0");

  const renderContent = () => {
    switch (activeTab) {
      case "tab0":
        return (
          <div>
            <h5>
              <span className="span-color-heading">EXTENDED PROFILE</span>
            </h5>

            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Link</strong>
                  </th>
                </tr>
                <tr>
                  <td>1.1</td>
                  <td>
                    <span>
                    Number of students year wise during the last five years 
                    </span>
                  </td>
                  <td>
                    <button className="view-button" onClick={handleEx1Click}> View</button>
                  </td>
                </tr>

                <tr>
                  <td>2.1</td>
                  <td>
                    <span>
                     Number of full time teachers during the last five years 
                    </span>
                  </td>
                  <td>
                    <button className="view-button" onClick={handleEx2Click}> View</button>
                  </td>
                </tr>

                <tr>
                  <td>3.1</td>
                  <td>
                    <span>
                    Expenditure excluding salary component year wise during the last five years
                    </span>
                  </td>
                  <td>
                    <button className="view-button" onClick={handleEx3Click}> View</button>
                  </td>
                </tr>


              </tbody>
            </table>

            <hr />
          </div>
        );
      case "tab1":
        return (
          <div>
            <h5>
              CRITERION 1 –{" "}
              <span className="span-color-heading">
                Curricular Aspects (100)
              </span>
            </h5>
            <hr />
            <h5>
              <span className="span-color-heading">Key Indicator -</span> 1.1
              Curricular Planning and Implementation
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>1.1.1</td>
                  <td>
                    <button onClick={handle111Click} className="link-button">
                      The Institution ensures effective curriculum planning and
                      delivery through a well-planned and documented process
                      including Academic calendar and conduct of continuous
                      internal Assessment
                    </button>
                  </td>
                  <td>20</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <h5>
              <span className="span-color-heading">Key Indicator -</span> 1.2
              Academic Flexibility
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>1.2.1</td>
                  <td>
                    <button onClick={handle121Click} className="link-button">
                      Number of Add on / Certificate/Value added programs
                      offered during the last five years
                      <br />
                      1.2.1.1 : Number of Add on /Certificate /Value added
                      programs offered during the last five years
                    </button>
                  </td>
                  <td>15</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h5>
              <span className="span-color-heading">Key Indicator -</span> 1.3
              Curriculum Enrichment (30)
            </h5>

            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>1.3.1</td>
                  <td>
                    <button onClick={handle131Click} className="link-button">
                      Institution integrates crosscutting issues relevant to
                      Professional Ethics, Gender, Human Values, Environment and
                      Sustainability into the Curriculum
                    </button>
                  </td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>1.3.2</td>
                  <td>
                    <button onClick={handle132Click} className="link-button">
                      Percentage of students undertaking project work/field
                      work/internships (Data for the latest completed academic
                      year) 1.3.2.1. Number of students undertaking project
                      work/field work / internships
                    </button>
                  </td>
                  <td>20</td>
                </tr>
              </tbody>
            </table>

            <hr />
            <h5>
              <span className="span-color-heading">Key Indicator -</span> 1.4
              Feedback System (20)
            </h5>

            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>1.4.1</td>
                  <td>
                    <button onClick={handle141Click} className="link-button">
                      Institution obtains feedback on the academic performance
                      and ambience of the institution from various stakeholders,
                      such as Students, Teachers, Employers, Alumni etc. and
                      action taken report on the feedback is made available on
                      institutional website (Yes or No)
                    </button>
                  </td>
                  <td>20</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case "tab2":
        return (
          <div>
            <h5>
              CRITERION 2 –{" "}
              <span className="span-color-heading">
                Teaching Learning and Evaluation(350)
              </span>
            </h5>
            <hr />
            <h5>
              <span className="span-color-heading">Key Indicator -</span> 2.1.
              Student Enrolment and Profile (40)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>2.1.1</td>
                  <td>
                    <button
                      onClick={handleEnrollementClick}
                      className="link-button"
                    >
                      Enrollment Percentage
                    </button>
                  </td>
                  <td>20</td>
                </tr>

                <tr>
                  <td>2.1.2</td>
                  <td>
                    <button onClick={handle212Click} className="link-button">
                      Percentage of seats filled against seats reserved for
                      various categories (SC, ST, OBC, Divyangjan, etc. as per
                      applicable reservation policy during the last five years)
                    </button>
                  </td>
                  <td>20</td>
                </tr>
              </tbody>
            </table>

            <hr />

            {/* New Content and Table */}
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              2.2. Student Teacher Ratio (40)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>2.2.1</td>
                  <td>
                    <button onClick={handle221Click} className="link-button">
                      Student – Full time Teacher Ratio
                      <br />
                      (Data for the latest completed academic year)
                    </button>
                  </td>
                  <td>40</td>
                </tr>
              </tbody>
            </table>

            <hr />

            {/* New Content and Table */}
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              2.3. Teaching- Learning Process (40)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>2.3.1</td>
                  <td>
                    <button onClick={handle231Click} className="link-button">
                      Student centric methods, such as experiential learning,
                      participative learning and problem solving methodologies
                      are used for enhancing
                    </button>
                  </td>
                  <td>40</td>
                </tr>
              </tbody>
            </table>

            <hr />

            {/* New Content and Table */}
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              2.4. Teacher Profile and Quality (40)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>2.4.1</td>
                  <td>
                    <button onClick={handle241Click} className="link-button">
                      Percentage of full-time teachers against sanctioned posts
                      during the last five years 2.4.1.1 Number of Sanctioned
                      posts / required positions for teaching staff/ full time
                      teachers year wise during the last five years
                    </button>
                  </td>
                  <td>15</td>
                </tr>

                <tr>
                  <td>2.4.2</td>
                  <td>
                    <button onClick={handle242Click} className="link-button">
                      Percentage of full time teachers with NET/SET/SLET/ Ph. D.
                      / D.M. / M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt.
                      during the last five years (consider only highest degree
                      for count) 2.4.2.1. Number of full time teachers with
                      NET/SET/SLET/Ph. D. / D.M. / M.Ch. / D.N.B Superspeciality
                      / D.Sc. / D.Litt. year wise during the last five years
                    </button>
                  </td>
                  <td>25</td>
                </tr>
              </tbody>
            </table>

            <hr />

            {/* New Content and Table */}
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              2.5. Evaluation Process and Reforms (40)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>2.5.1</td>
                  <td>
                    <button onClick={handle251Click} className="link-button">
                      Mechanism of internal/ external assessment is transparent
                      and the grievance redressal system is time- bound and
                      efficient
                    </button>
                  </td>
                  <td>40</td>
                </tr>
              </tbody>
            </table>

            <hr />

            {/* New Content and Table */}
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              2.6 Student Performance and Learning Outcome (90)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>2.6.1</td>
                  <td>
                    <button onClick={handle261Click} className="link-button">
                      Programme Outcomes (POs) and Course Outcomes (COs) for all
                      Programmes offered by the institution are stated and
                      displayed on website
                    </button>
                  </td>
                  <td>20</td>
                </tr>

                <tr>
                  <td>2.6.2</td>
                  <td>
                    <button onClick={handle262Click} className="link-button">
                      Attainment of POs and COs are evaluated
                    </button>
                  </td>
                  <td>25</td>
                </tr>

                <tr>
                  <td>2.6.3</td>
                  <td>
                    <button onClick={handle263Click} className="link-button">
                      Pass percentage of Students during last five years
                      2.6.2.1. Number of final year students who passed the
                      university examination year wise during the last five
                      years
                    </button>
                  </td>
                  <td>45</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case "tab3":
        return (
          <div>
            <h5>
              CRITERION 3 -{" "}
              <span className="span-color-heading">
                Research, Innovations and Extension (110) Institutional Values
                and Best Practices (100)
              </span>
            </h5>
            <hr />

            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              3.1 Resource Mobilization for Research (10)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>3.1.1</td>
                  <td>
                    <button onClick={handle311Click} className="link-button">
                      Grants received from Government and non-governmental
                      agencies for research projects / endowments in the
                      institution during the last five years (INR in Lakhs)
                    </button>
                  </td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>

            <hr />

            {/* New Content and Table */}
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              3.2 Innovation Ecosystem (15)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>3.2.1</td>
                  <td>
                    <button onClick={handle321Click} className="link-button">
                      Institution has created an ecosystem for innovations and
                      has initiatives for creation and transfer of knowledge
                      (patents filed, published, incubation center facilities in
                      the HEI to be considered)
                    </button>
                  </td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>3.2.2</td>
                  <td>
                    <button onClick={handle322Click} className="link-button">
                      Number of workshops/seminars/conferences including on
                      Research Methodology, Intellectual Property Rights (IPR)
                      and entrepreneurship conducted during the last five years
                      3.2.2.1: Total number of workshops/seminars/conferences
                      including programs conducted on Research Methodology,
                      Intellectual Property Rights (IPR) and entrepreneurship
                      year wise during last five years
                    </button>
                  </td>
                  <td>5</td>
                </tr>
              </tbody>
            </table>
            <hr />

            <hr />

            {/* New Content and Table */}
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              3.3 - Research Publication and Awards (25)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>3.3.1</td>
                  <td>
                    <button onClick={handle331Click} className="link-button">
                      Number of research papers published per teacher in the
                      Journals notified on UGC care list during the last five
                      years 3.3.1.1. Number of research papers in the Journals
                      notified on UGC CARE year wise during the last five years
                    </button>
                  </td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>3.3.2</td>
                  <td>
                    <button onClick={handle332Click} className="link-button">
                      Number of books and chapters in edited volumes/books
                      published and papers published in national/ international
                      conference proceedings per teacher during last five years
                      3.3.2.1. Total number of books and chapters in edited
                      volumes/books published and papers in national/
                      international conference proceedings year wise during last
                      five years
                    </button>
                  </td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>

            <hr />
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              3.4 Extension Activities (40)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>3.4.1</td>
                  <td>
                    <button onClick={handle341Click} className="link-button">
                      Extension activities are carried out in the neighborhood
                      community, sensitizing students to social issues, for
                      their holistic development, and impact thereof during the
                      last five years.
                    </button>
                  </td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>3.4.2</td>
                  <td>
                    <button onClick={handle342Click} className="link-button">
                      Awards and recognitions received for extension activities
                      from government / government recognised bodies
                    </button>
                  </td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>3.4.3</td>
                  <td>
                    <button onClick={handle343Click} className="link-button">
                      Number of extension and outreach programs conducted by the
                      institution through NSS/NCC/Red cross/YRC etc., (including
                      the programmes such as Swachh Bharat, AIDS awareness,
                      Gender issues etc. and/or those organised in collaboration
                      with industry, community and NGOs) during the last five
                      years 3.4.3.1. Number of extension and outreach Programs
                      conducted in collaboration with industry, community, and
                      Non- Government Organizations through NSS/ NCC/ Red Cross/
                      YRC etc., year wise during the last five years
                    </button>
                  </td>
                  <td>20</td>
                </tr>
              </tbody>
            </table>

            <hr />
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              3.5 Collaboration (20)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>3.5.1</td>
                  <td>
                    <button onClick={handle351Click} className="link-button">
                      The number of MoUs, collaborations/linkages for Faculty
                      exchange, Student exchange, Internship, Field trip,
                      On-the- job training, research and other academic
                      activities during the last five years
                    </button>
                  </td>
                  <td>20</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case "tab4":
        return (
          <div>
            <h5>
              CRITERION 4 –{" "}
              <span className="span-color-heading">
                Infrastructure and Learning Resources (100)
              </span>
            </h5>
            <hr />
            <h5>
              <span className="span-color-heading">Key Indicator -</span> 4.1
              Physical Facilities (30)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>4.1.1</td>
                  <td>
                    <button onClick={handle411Click} className="link-button">
                      Availability of adequate infrastructure and physical
                      facilities viz., classrooms, laboratories, ICT facilities,
                      cultural activities, gymnasium, yoga centre etc. in the
                      institution
                    </button>
                  </td>
                  <td>20</td>
                </tr>

                <tr>
                  <td>4.1.2</td>
                  <td>
                    <button onClick={handle412Click} className="link-button">
                      Percentage of expenditure, excluding salary for
                      infrastructure augmentation during last five years (INR in
                      Lakhs)
                      <br />
                      4.1.2.1 Expenditure for infrastructure augmentation,
                      excluding salary year wise during last five years (INR in
                      lakhs)
                    </button>
                  </td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>
            <hr />

            <h5>
              <span className="span-color-heading">Key Indicator -</span> 4.2
              Library as a learning Resource (20)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>4.2.1</td>
                  <td>
                    <button onClick={handle421Click} className="link-button">
                      Library is automated using Integrated Library Management
                      System (ILMS), subscription to e-resources, amount spent
                      on purchase of books, journals and per day usage of
                      library
                    </button>
                  </td>
                  <td>20</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <h5>
              <span className="span-color-heading">Key Indicator -</span> 4.3 IT
              Infrastructure (30)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>4.3.1</td>
                  <td>
                    <button onClick={handle431Click} className="link-button">
                      Institution frequently updates its IT facilities and
                      provides sufficient bandwidth for internet connection
                    </button>
                  </td>
                  <td>20</td>
                </tr>

                <tr>
                  <td>4.3.2</td>
                  <td>
                    <button onClick={handle432Click} className="link-button">
                      Student – Computer ratio (Data for the latest completed
                      academic year)
                      <br />
                      4.3.2.1. Number of computers available for students usage
                      during the latest completed academic year
                    </button>
                  </td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h5>
              <span className="span-color-heading">Key Indicator -</span> 4.4
              Maintenance of Campus Infrastructure (20)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>4.4.1</td>
                  <td>
                    <button onClick={handle441Click} className="link-button">
                      Percentage of expenditure incurred on maintenance of
                      infrastructure (physical and academic support facilities)
                      excluding salary component during the last five years (INR
                      in Lakhs) 4.4.1.1. Expenditure incurred on maintenance of
                      infrastructure (physical facilities and academic support
                      facilities) excluding salary component year wise during
                      the last five years (INR in lakhs)
                    </button>
                  </td>
                  <td>20</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case "tab5":
        return (
          <div>
            <h5>
              CRITERION 5 -{" "}
              <span className="span-color-heading">
                Student Support and Progression (140)
              </span>
            </h5>
            <hr />

            {/* New Content and Table */}
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              5.1 Student Support (50)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>5.1.1</td>
                  <td>
                    <button onClick={handle511Click} className="link-button">
                      Percentage of students benefited by scholarships and
                      freeships provided by the Government and Non-Government
                      agencies during last five years
                    </button>
                    <hr />
                    <button onClick={handle511Click} className="link-button">
                      5.1.1.1. Number of students benefited by scholarships and
                      freeships provided by the Government and Non-Government
                      agencies year wise during last five years
                    </button>
                  </td>
                  <td>20</td>
                </tr>

                <tr>
                  <td>5.1.2</td>
                  <td>
                    <button onClick={handle512Click} className="link-button">
                      Capacity building and skills enhancement initiatives taken
                      by the institution include the following
                      <ul>
                        <ol>1. Soft skills</ol>
                        <ol> 2. Language and communication skills</ol>
                        <ol>
                          {" "}
                          3. Life skills (Yoga, physical fitness, health and
                          hygiene)
                        </ol>
                        <ol> 4. ICT/computing skills</ol>
                      </ul>
                    </button>
                  </td>
                  <td>15</td>
                </tr>

                <tr>
                  <td>5.1.3</td>
                  <td>
                    <button onClick={handle513Click} className="link-button">
                      Percentage of students benefitted by guidance for
                      competitive examinations and career counseling offered by
                      the Institution during last five years
                    </button>
                    <hr />
                    <button onClick={handle513Click} className="link-button">
                      5.1.3.1. Number of students benefitted by guidance for
                      competitive examinations and career counselling offered by
                      the institution year wise during last five years
                    </button>
                  </td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>5.1.4</td>
                  <td>
                    <button onClick={handle514Click} className="link-button">
                      The Institution has a transparent mechanism for timely
                      redressal of student grievances including sexual
                      harassment and ragging cases
                      <ul>
                        <ol>
                          1. Implementation of guidelines of
                          statutory/regulatory bodies
                        </ol>
                        <ol>
                          {" "}
                          2. Organisation wide awareness and undertakings on
                          policies with zero tolerance
                        </ol>
                        <ol>
                          {" "}
                          3. Mechanisms for submission of online/offline
                          students’ grievances
                        </ol>
                        <ol>
                          {" "}
                          4. Timely redressal of the grievances through
                          appropriate committees
                        </ol>
                      </ul>
                    </button>
                  </td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>

            {/* <h5>
              CRITERION 5 -{" "}
              <span className="span-color-heading">
                Student Support and Progression (140)
              </span>
            </h5> */}
            <hr />

            {/* New Content and Table */}

            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              5.2 Student Progression (30)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>5.2.1</td>
                  <td>
                    <button onClick={handle521Click} className="link-button">
                      Percentage of placement of outgoing students and students
                      progressing to higher education during the last five years
                      <br />
                      5.2.1.1. Number of outgoing students placed and / or
                      progressed to higher education year wise during the last
                      five years
                      <br />
                      5.2.1.2. Number of outgoing students year wise during the
                      last five years
                    </button>
                  </td>
                  <td>20</td>
                </tr>
                <tr>
                  <td>5.2.2</td>
                  <td>
                    <button onClick={handle522Click} className="link-button">
                      Percentage of students qualifying in state/national/
                      international level examinations during the last five
                      years
                      <br />
                      (eg: JAM/CLAT/GATE/ GMAT/ CAT/ GRE/ TOEFL/ Civil
                      Services/State government examinations)
                    </button>
                  </td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>
            <hr />

            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              5.3 Student Participation and Activities (50)
            </h5>
            <hr />

            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>5.3.1</td>
                  <td>
                    <button onClick={handle531Click} className="link-button">
                      Number of awards/medals for outstanding performance in
                      sports/ cultural activities at University / state/
                      national / international level (award for a team event
                      should be counted as one) during the last five years
                    </button>
                  </td>
                  <td>25</td>
                </tr>

                <tr>
                  <td>5.3.2</td>
                  <td>
                    <button onClick={handle532Click} className="link-button">
                      Average number of sports and cultural programs in which
                      students of the Institution participated during last five
                      years (organised by the institution/other institutions)
                      <hr />
                      5.3.2.1. Number of sports and cultural programs in which
                      students of the Institution participated year wise during
                      last five years
                    </button>
                  </td>
                  <td>25</td>
                </tr>
              </tbody>
            </table>
            <hr />

            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              5.4 Alumni Engagement (10)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>
                <tr>
                  <td>5.4.1</td>
                  <td>
                    <button onClick={handle541Click} className="link-button">
                      There is a registered Alumni Association that contributes
                      significantly to the development of the institution
                      through financial and/or other support services
                    </button>
                  </td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case "tab6":
        return (
          <div>
            <h5>
              CRITERION 6 -{" "}
              <span className="span-color-heading">
                Governance, Leadership and Management (100)
              </span>
            </h5>
            <hr />

            {/* New Content and Table */}
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              6.1 Institutional Vision and Leadership (10)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>6.1.1</td>
                  <td>
                    <button onClick={handle611Click} className="link-button">
                      The governance and leadership is in accordance with vision
                      and mission of the institution and it is visible in
                      various institutional practices such as decentralization
                      and participation in the institutional governance
                    </button>
                  </td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              6.2 Strategy Development and Deployment (10)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>6.2.1</td>
                  <td>
                    <button onClick={handle621Click} className="link-button">
                      The functioning of the institutional bodies is effective
                      and efficient as visible from policies, administrative
                      setup, appointment and service rules, procedures,
                      deployment of institutional
                      Strategic/perspective/development plan etc
                    </button>
                  </td>
                  <td>6</td>
                </tr>
                <tr>
                  <td>6.2.2</td>
                  <td>
                    <button onClick={handle622Click} className="link-button">
                      Implementation of e-governance in areas of operation{" "}
                      <br />
                      1. Administration <br />
                      2. Finance and Accounts
                      <br /> 3. Student Admission and Support <br />
                      4. Examination
                    </button>
                  </td>
                  <td>6</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              6.3 Faculty Empowerment Strategies (35)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>6.3.1</td>
                  <td>
                    <button onClick={handle631Click} className="link-button">
                      The institution has effective welfare measures and
                      Performance Appraisal System for teaching and non-teaching
                      staff.
                    </button>
                  </td>
                  <td>8</td>
                </tr>

                <tr>
                  <td>6.3.2</td>
                  <td>
                    <button onClick={handle632Click} className="link-button">
                      Percentage of teachers provided with financial support to
                      attend conferences/workshops and towards membership fee of
                      professional bodies during the last five years
                    </button>
                  </td>
                  <td>12</td>
                </tr>

                <tr>
                  <td>6.3.3</td>
                  <td>
                    <button onClick={handle633Click} className="link-button">
                      Percentage of teaching and non-teaching staff
                      participating in Faculty development Programmes (FDP),
                      professional development / administrative training
                      programs during the last five years 6.3.3.1. Total number
                      of teaching and non-teaching staff participating in
                      Faculty development Programmes (FDP), professional
                      development /administrative training programs during the
                      last five years 6.3.3.2 Number of non-teaching staff year
                      wise during the last five years
                    </button>
                  </td>
                  <td>15</td>
                </tr>
              </tbody>
            </table>

            <hr />
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              6.4 Financial Management and Resource Mobilization (15)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>6.4.1</td>
                  <td>
                    <button onClick={handle641Click} className="link-button">
                      Institution has strategies for mobilization and optimal
                      utilization of resources and funds from various sources
                      <br />
                      (government/ nongovernment organizations) and it conducts
                      financial audits regularly (internal and external)
                    </button>
                  </td>
                  <td>15</td>
                </tr>
              </tbody>
            </table>

            <hr />
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              6.5 Internal Quality Assurance System (30)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>6.5.1</td>
                  <td>
                    <button onClick={handle651Click} className="link-button">
                      Internal Quality Assurance Cell (IQAC) has contributed
                      significantly for institutionalizing the quality assurance
                      strategies and processes. It reviews teaching learning
                      process, structures & methodologies of operations and
                      learning outcomes at periodic intervals and records the
                      incremental improvement in various activities
                    </button>
                  </td>
                  <td>15</td>
                </tr>

                <tr>
                  <td>6.5.2</td>
                  <td>
                    <button onClick={handle652Click} className="link-button">
                      Quality assurance initiatives of the institution include:
                      <br />
                      1. Regular meeting of Internal Quality Assurance Cell
                      (IQAC); Feedback collected, analysed and used for
                      improvements
                      <br />
                      2. Collaborative quality initiatives with other
                      institution(s) / membership of international networks
                      <br />
                      3. Participation in NIRF
                      <br />
                      4. any other quality audit/accreditation recognized by
                      state, national or international agencies such as NAAC,
                      NBA, ISO Certification etc
                    </button>
                  </td>
                  <td>15</td>
                </tr>
              </tbody>
            </table>
          </div>
        );

      case "tab7":
        return (
          <div>
            <h5>
              CRITERION 7 -{" "}
              <span className="span-color-heading">
                Institutional Values and Best Practices (100)
              </span>
            </h5>
            <hr />

            {/* New Content and Table */}
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              7.1 Institutional Values and Social Responsibilities (50)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>7.1.1</td>
                  <td>
                    <button onClick={handle711Click} className="link-button">
                      Measures initiated by the Institution for the promotion of
                      gender equity and Institutional initiatives to celebrate /
                      organize national and international commemorative days,
                      events and festivals during the last five years
                    </button>
                  </td>
                  <td>20</td>
                </tr>

                <tr>
                  <td>7.1.2</td>
                  <td>
                    <button onClick={handle712Click} className="link-button">
                      The Institution has facilities and initiatives for
                      <br />
                      1. Alternate sources of energy and energy conservation
                      measures
                      <br />
                      2. Management of the various types of degradable and
                      nondegradable waste
                      <br />
                      3. Water conservation
                      <br />
                      4. Green campus initiatives
                      <br />
                      5. Disabled-friendly, barrier free environment
                    </button>
                  </td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>7.1.3</td>
                  <td>
                    <button onClick={handle713Click} className="link-button">
                      Quality audits on environment and energy regularly
                      undertaken by the Institution. The institutional
                      environment and energy initiatives are confirmed through
                      the following <br />
                      1. Green audit / Environment audit
                      <br /> 2. Energy audit <br />
                      3. Clean and green campus initiatives <br />
                      4. Beyond the campus environmental promotion activities
                    </button>
                  </td>
                  <td>10</td>
                </tr>

                <tr>
                  <td>7.1.4</td>
                  <td>
                    <button onClick={handle714Click} className="link-button">
                      Describe the Institutional efforts/initiatives in
                      providing an inclusive environment i.e., tolerance and
                      harmony towards cultural, regional, linguistic, communal
                      socioeconomic and Sensitization of students and employees
                      to the constitutional obligations: values, rights, duties
                      and responsibilities of citizens
                    </button>
                  </td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              7.2 Best Practices (30)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>7.2.1</td>
                  <td>
                    <button onClick={handle721Click} className="link-button">
                      Describe two best practices successfully implemented by
                      the Institution as per NAAC format provided in the Manual
                    </button>
                  </td>
                  <td>20</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h5>
              <span className="span-color-heading">Key Indicator - </span>
              7.3 Institutional Distinctiveness (20)
            </h5>
            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Weightage</strong>
                  </th>
                </tr>

                <tr>
                  <td>7.3.1</td>
                  <td>
                    <button onClick={handle731Click} className="link-button">
                      Portray the performance of the Institution in one area
                      distinctive to its priority and thrust within 1000 words
                    </button>
                  </td>
                  <td>20</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      default:
        return <p>Content for Tab 0</p>;
    }
  };

  return (
    <>
      <Header />
      <div className="image-section">
        <img src={image3} alt="Gallery" className="department-image" />
        <div className="overlay-text">SSR Metrics</div>
      </div>
      <div className="naac-container">
        <div className="tabs">
          <button
            className={`tab-button ${activeTab === "tab0" ? "active" : ""}`}
            onClick={() => setActiveTab("tab0")}
          >
            EXTENDED PROFILE
          </button>
          <button
            className={`tab-button ${activeTab === "tab1" ? "active" : ""}`}
            onClick={() => setActiveTab("tab1")}
          >
            CRITERION 1
          </button>
          <button
            className={`tab-button ${activeTab === "tab2" ? "active" : ""}`}
            onClick={() => setActiveTab("tab2")}
          >
            CRITERION 2
          </button>
          <button
            className={`tab-button ${activeTab === "tab3" ? "active" : ""}`}
            onClick={() => setActiveTab("tab3")}
          >
            CRITERION 3
          </button>
          <button
            className={`tab-button ${activeTab === "tab4" ? "active" : ""}`}
            onClick={() => setActiveTab("tab4")}
          >
            CRITERION 4
          </button>
          <button
            className={`tab-button ${activeTab === "tab5" ? "active" : ""}`}
            onClick={() => setActiveTab("tab5")}
          >
            CRITERION 5
          </button>
          <button
            className={`tab-button ${activeTab === "tab6" ? "active" : ""}`}
            onClick={() => setActiveTab("tab6")}
          >
            CRITERION 6
          </button>
          <button
            className={`tab-button ${activeTab === "tab7" ? "active" : ""}`}
            onClick={() => setActiveTab("tab7")}
          >
            CRITERION 7
          </button>
        </div>
        <div className="content-naac">{renderContent()}</div>
      </div>
      <Footer />
    </>
  );
};

export default NAAC;
