import React, { useState } from "react";
import Header from "../../commons/header/header";
import Footer from "../../commons/footer/footer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import "./capacity.css"; // Ensure you have a CSS file for styling
import image1 from "../../../assests/images/softskill1.png"; // Replace this with real images
import image2 from "../../../assests/images/softskill2.png"; // Replace this with real images
import image3 from "../../../assests/images/softskill3.png"; // Replace this with real images
import image4 from "../../../assests/images/softskill4.png"; // Replace this with real images
import image5 from "../../../assests/images/softskill5.png"; // Replace this with real images

import l1 from "../../../assests/images/l1.png"; // Replace this with real images
import l2 from "../../../assests/images/l2.png"; // Replace this with real images
import l3 from "../../../assests/images/l3.png"; // Replace this with real images
import l4 from "../../../assests/images/l4.png"; // Replace this with real images
import l5 from "../../../assests/images/l5.png"; // Replace this with real images
import l6 from "../../../assests/images/l6.png"; // Replace this with real images

import ls1 from "../../../assests/images/ls1.png"; // Replace this with real images
import ls2 from "../../../assests/images/ls2.png"; // Replace this with real images
import ls3 from "../../../assests/images/ls3.png"; // Replace this with real images
import ls4 from "../../../assests/images/ls4.png"; // Replace this with real images
import ls5 from "../../../assests/images/ls5.png"; // Replace this with real images

import ict1 from "../../../assests/images/ict1.png"; // Replace this with real images
import ict2 from "../../../assests/images/ict2.png"; // Replace this with real images
import ict3 from "../../../assests/images/ict3.png"; // Replace this with real images
import ict4 from "../../../assests/images/ict4.png"; // Replace this with real images
import ict5 from "../../../assests/images/ict5.png"; // Replace this with real images
import ict6 from "../../../assests/images/ict6.png"; // Replace this with real images
import ict7 from "../../../assests/images/ict7.png"; // Replace this with real images
import ict8 from "../../../assests/images/ict8.png"; // Replace this with real images



const CapacityBuilding = () => {
  const [activeTab, setActiveTab] = useState("Soft Skills");

  const renderContent = () => {
    switch (activeTab) {
      case "Soft Skills":
        return (
          <div className="tab-content">
            <h2>Soft Skills</h2>
            <p>
              Soft skills are a requisite of student success in both academic
              and professional milieu. Soft skills involve a person's ability to
              communicate, collaborate, solve problems, manage time, and work
              effectively with others. ACET conducts several soft skill programs
              every year to make our students more efficient and competitive in
              the corporate world.
            </p>
            <ul className="skills-list">
              <li>
                <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                Barriers to Effective Communication
              </li>
              <li>
                <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                Adaptability
              </li>
              <li>
                <i className="fa fa-check-circle" aria-hidden="true"></i> Active
                Listening
              </li>
              <li>
                <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                Critical Thinking & Problem Solving
              </li>
              <li>
                <i className="fa fa-check-circle" aria-hidden="true"></i> Work
                Ethics and etc.
              </li>
            </ul>
            <div className="image-gallery">
              <img src={image1} alt="Soft Skills" className="gallery-image" />
              <img src={image2} alt="Soft Skills" className="gallery-image" />
              <img src={image3} alt="Soft Skills" className="gallery-image" />
              <img src={image4} alt="Soft Skills" className="gallery-image" />
              <img src={image5} alt="Soft Skills" className="gallery-image" />

              {/* Add more images if available */}
            </div>
          </div>
        );
      case "Language Communication Skills":
        return (
          <div className="tab-content">
            <h2>Language And Communication Skills</h2>
            <p>
              There are so many different forms of good communication, but the
              most common are verbal and writing abilities. It means all the
              different ways a person understands and communicates, only part of
              which are spoken words. Communication and language progress is
              important, because speaking is an indicator of fine motor skill
              development and a reflection of cognitive development. It's
              essential for understanding course material, participating in
              class discussions, and expressing ideas. More than that, it plays
              a pivotal role in many fabrication and vocation.
            </p>
            <div className="image-gallery">
              <img src={l1} alt="Language Skills" className="gallery-image" />
              <img src={l2} alt="Language Skills" className="gallery-image" />
              <img src={l3} alt="Language Skills" className="gallery-image" />
              <img src={l4} alt="Language Skills" className="gallery-image" />
              <img src={l5} alt="Language Skills" className="gallery-image" />
              <img src={l6} alt="Language Skills" className="gallery-image" />
            </div>
          </div>
        );
      case "Life Skills":
        return (
          <div className="tab-content">
            <h2>Life Skills</h2>
            <p>
              Life skills are invaluable assets that can help young adults
              navigate the challenges of daily living, so College should provide
              their students with practical lessons in daily living, such as
              decision-making, managing emotions, and problem-solving – skills
              that will prepare them to enter the real world and live ... The
              abilities for adaptive and positive behaviour that enable
              individuals to deal effectively with the demands and challenges of
              everyday life. These four pillars are - learning to know, learning
              to do, learning to be and learning to live together. life skills
              as 'personal management and social skills which are necessary for
              adequate functioning on an independent basis'
            </p>
            <div className="image-gallery">
              <img src={ls1} alt="Life Skills" className="gallery-image" />
              <img src={ls2} alt="Life Skills" className="gallery-image" />
              <img src={ls3} alt="Life Skills" className="gallery-image" />
              <img src={ls4} alt="Life Skills" className="gallery-image" />
              <img src={ls5} alt="Life Skills" className="gallery-image" />
            </div>
          </div>
        );
      case "ICT/Computing Skills":
        return (
          <div className="tab-content">
            <h2>ICT/Computing Skills</h2>
            <p>
              A diverse set of technological tools and resources used to
              transmit, store, create, share or exchange information. <br />
              ICT enables the development of online learning platforms and
              virtual classrooms. This allows students from different locations,
              backgrounds, and time zones to connect and learn together. It
              fosters global collaboration and cultural understanding, preparing
              students for an increasingly interconnected world.
              <br />
              Information and communication technologies are fast, automated,
              interactive and rapidly changing. They support the communication
              and representation of knowledge to many audiences. They transform
              the ways that students think and learn and give them greater
              control over how, where and when they learn.
            </p>
            <div className="image-gallery">
              <img src={ict1} alt="ICT Skills" className="gallery-image" />
              <img src={ict2} alt="ICT Skills" className="gallery-image" />
              <img src={ict3} alt="ICT Skills" className="gallery-image" />
              <img src={ict4} alt="ICT Skills" className="gallery-image" />
              <img src={ict5} alt="ICT Skills" className="gallery-image" />
              <img src={ict6} alt="ICT Skills" className="gallery-image" />
              <img src={ict7} alt="ICT Skills" className="gallery-image" />
              <img src={ict8} alt="ICT Skills" className="gallery-image" />

            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Header />
      <div className="main-container">
        <div className="tabs-section">
          <button
            onClick={() => setActiveTab("Soft Skills")}
            className={activeTab === "Soft Skills" ? "active" : ""}
          >
            Soft Skills
          </button>
          <button
            onClick={() => setActiveTab("Language Communication Skills")}
            className={
              activeTab === "Language Communication Skills" ? "active" : ""
            }
          >
            Language Communication Skills
          </button>
          <button
            onClick={() => setActiveTab("Life Skills")}
            className={activeTab === "Life Skills" ? "active" : ""}
          >
            Life Skills
          </button>
          <button
            onClick={() => setActiveTab("ICT/Computing Skills")}
            className={activeTab === "ICT/Computing Skills" ? "active" : ""}
          >
            ICT/Computing Skills
          </button>
        </div>

        <div className="content-section">{renderContent()}</div>
      </div>
      <Footer />
    </>
  );
};

export default CapacityBuilding;
