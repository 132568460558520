// src/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import "./footer.css"; // Make sure to import the CSS

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>About Us</h4>
          <p>
            We are a dedicated institution committed to providing quality
            education and fostering innovation. Our programs are designed to
            empower students with the skills and knowledge needed to succeed in
            their chosen fields. With a strong focus on research and practical
            learning, we strive to shape the future leaders and professionals of
            tomorrow.
          </p>
        </div>
        <div className="footer-section quick-links">
          <h4>Quick Links</h4>
          <ul>
            <li>
              <Link to="/">HOME</Link>
            </li>
           <li>
              <Link to="/aboutus">ABOUT</Link>
            </li>

             {/* <li>
              <Link to="/placement">PLACEMENT</Link>
            </li>
            <li>
              <Link to="/gallery">GALLERY</Link>
            </li>
            <li>
              <Link to="#admission">ADMISSION</Link>
            </li>
            <li>
              <Link to="/contact">CONTACT</Link>
            </li> */}
            <li>
              <Link to="https://www.aicte-india.org/" target="_blank" rel="noopener noreferrer">AICTE</Link>
            </li>
            <li>
              <Link to="https://www.antiragging.in/compliance_desclaimer.html" target="_blank" rel="noopener noreferrer">
                ANITI-RAGGING
              </Link>
            </li>
            <li>
              <Link to="https://www.ugc.gov.in/" target="_blank" rel="noopener noreferrer">UGC</Link>
            </li>
            <li>
              <Link to="https://www.vishaka.org/" target="_blank" rel="noopener noreferrer">VISHAKA FOR WOMEN</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Courses Offered</h4>
          <ul>
            <li>
              <Link to="/department-details/cse">
                B.E Computer Science and Engineering
              </Link>
            </li>
            <li>
              <Link to="/department-details/eee">
                B.E Electrical and Electronics Engineering
              </Link>
            </li>
            <li>
              <Link to="/department-details/mech">
                B.E Mechanical Engineering
              </Link>
            </li>
            <li>
              <Link to="/department-details/biomedical">
                B.E Biomedical Engineering
              </Link>
            </li>
            <li>
              <Link to="/department-details/agri">
                B.Tech Agricultural Engineering
              </Link>
            </li>
            <li>
              <Link to="/department-details/aids">
                B.Tech Artificial Intelligence and Data Science
              </Link>
            </li>
            <li>
              <Link to="/department-details/it">
                B.Tech Information Technology
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-section map-section">
          <h4>Location Map</h4>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3914.6794011666634!2d77.01440437481045!3d11.137237589034271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8f9e4db0013ed%3A0xe05c21d2c33a7b6e!2sAsian%20College%20of%20Engineering%20%26%20Technology!5e0!3m2!1sen!2sin!4v1721986031909!5m2!1sen!2sin"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Location Map"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <p>
          &copy; 2024{" "}
          <a
            href="https://www.jeginatechnologies.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Jegina Technologies
          </a>
          . All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;