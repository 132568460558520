import React, { useState } from "react";
import Header from "../../commons/header/header";
import Footer from "../../commons/footer/footer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
} from "@mui/material";
import "./iqac.css"; // Import the CSS file

const IQAC = () => {
  const [selectedTab, setSelectedTab] = useState("committee");

  const rows = [
    {
      id: 1,
      name: "Dr. K. M. Savithri",
      designation: "PRINCIPAL",
      category: "Chair person",
    },
    {
      id: 2,
      name: "Dr. S. Aejitha",
      designation: "HOD / S & H",
      category: "IQAC Coordinator",
    },
    {
      id: 3,
      name: "Mr. S. Prabhu",
      designation: "CEO",
      category: "Member from Management",
    },
    {
      id: 4,
      name: "Mr. T. Babu Senthilkumar",
      designation: "ADMINISTRATIVE OFFICER",
      category: "Administrative Office",
    },
    {
      id: 5,
      name: "Mr. D. Senthilbabu",
      designation: "HOD / BME",
      category: "Assistant Professor",
    },
    {
      id: 6,
      name: "Mr. V. Gowthaman",
      designation: "HOD / CSE",
      category: "Associate Professor",
    },
    {
      id: 7,
      name: "Mrs. S. Nancy Priya",
      designation: "HOD / AGRI",
      category: "Assistant Professor",
    },
    {
      id: 8,
      name: "Mr. M.S. Praveen",
      designation: "HOD / MECH",
      category: "Assistant Professor",
    },
    {
      id: 9,
      name: "Mrs. N. Renugadevi",
      designation: "HOD / EEE",
      category: "Assistant Professor",
    },
    {
      id: 10,
      name: "Dr. A. Thirunavukkarasu",
      designation: "HOD / AI & DS",
      category: "Professor",
    },
    {
      id: 11,
      name: "Mr. V. Govindharajan",
      designation: "President, Kondayampalayam Village Panchayat",
      category: "LOCAL SOCIETY",
    },
    {
      id: 12,
      name: "Mr. B. Suthi",
      designation: "IV YEAR / CSE",
      category: "Student",
    },
    {
      id: 13,
      name: "Ms. G. Sindhumathi",
      designation: "ALUMNI (2023- Batch)",
      category: "Alumni",
    },
    {
      id: 14,
      name: "Mr. R. Jeevananthan",
      designation: "Software Engineer, Clover Infotech Pvt. Ltd, Chennai",
      category: "Employers",
    },
    {
      id: 15,
      name: "Mr. M. Ponraj",
      designation: "CEO, HITAJE Groups, Kurumpapalayam, Coimbatore",
      category: "Industrialists",
    },
    {
      id: 16,
      name: "Mr. T. Nagaraj",
      designation: "F / o. N. Naveen / III - CSE",
      category: "Stakeholders",
    },
  ];

  const handlePDFClick = () => {
    window.open(
      `${process.env.PUBLIC_URL}/pdf/iqac_minutes_of_meeting.pdf`,
      "_blank"
    );
  };
  return (
    <>
      <Header />
      <div className="antiragging-container">
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          width="100%"
        >
          {/* Left side menu */}
          <Box
            flex={{ xs: "none", sm: "0 0 200px" }}
            bgcolor="transparent"
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap={1} // Add spacing between buttons
            sx={{
              // Responsive styling
              "@media (max-width: 600px)": {
                flex: "0 0 auto",
              },
              "@media (min-width: 600px)": {
                flex: "0 0 200px",
              },
            }}
          >
            <Button
              variant={selectedTab === "committee" ? "contained" : "outlined"}
              onClick={() => setSelectedTab("committee")}
              style={{
                width: "100%",
                color: selectedTab === "committee" ? "#004d40" : "#000",
                borderColor:
                  selectedTab === "committee" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              IQAC
            </Button>
            <Button
              variant={selectedTab === "mom" ? "contained" : "outlined"}
              onClick={handlePDFClick}
              style={{
                width: "100%",
                color: selectedTab === "mom" ? "#004d40" : "#000",
                borderColor: selectedTab === "mom" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              Minutes Of Meeting
            </Button>
          </Box>

          {/* Right side content */}
          <Box flex="1" p={2}>
            <h2>{selectedTab === "committee" ? "IQAC" : "mom"}</h2>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      Name of the Members
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      Designation
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      Category
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(selectedTab === "committee"
                    ? rows
                    : selectedTab === "mom"
                  ).map((row, index) => (
                    <TableRow
                      key={row.id}
                      style={{
                        backgroundColor: index % 2 === 0 ? "white" : "#f1f8e9",
                      }}
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.designation}</TableCell>
                      <TableCell>{row.category}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default IQAC;
