import React from "react";
import Header from "../../commons/header/header";
import Footer from "../../commons/footer/footer";
import "./feedback.css"; // Ensure you have a CSS file for styling

const Feedback = () => {
  
  const handleStudentClick = () => {
    window.open(`https://acetcbe.kredovoiceout.in/#/el/10000006`, "_blank");
  };
  const handleTeachersClick = () => {
    window.open(`https://acetcbe.kredovoiceout.in/#/el/10000007`, "_blank");
  };
  const handleAluminiClick = () => {
    window.open(`https://acetcbe.kredovoiceout.in/#/el/10000008`, "_blank");
  };
  const handleEmployerClick = () => {
    window.open(`https://acetcbe.kredovoiceout.in/#/el/10000009`, "_blank");
  };


  const handleActionTakenReportClick = () => {
    window.open(`https://acetcbe.in/pdf/CRITERION-1/1.4.1/ActiontakenReport.pdf`, "_blank");
  };


  const handleAnalysisReportClick = () => {
    window.open(`https://acetcbe.in/pdf/CRITERION-1/1.4.1/FeedbackAnalysis.pdf`, "_blank");
  };


  return (
    <>
      <Header />
      <div className="feedback-container">
        <div className="tabs-container">
          <div
            className={`tab`}
            onClick={handleEmployerClick}
          >
            Employers Feedback
          </div>
          <div
            className={`tab`}
            onClick={handleAluminiClick}
          >
            Alumni Feedback
          </div>
          <div
            className={`tab`}
            onClick={handleTeachersClick}
          >
            Teachers Feedback
          </div>
          <div
            className={`tab`}
            onClick={handleStudentClick}
          >
            Student Feedback
          </div>

          <div
            className={`tab`}
            onClick={handleAnalysisReportClick}
          >
            Analysis Report
          </div>


          <div
            className={`tab`}
            onClick={handleActionTakenReportClick}
          >
            Action Taken Report
          </div>


        </div>
      </div>
      <Footer />
    </>
  );
};

export default Feedback;
