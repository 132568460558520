import React, { useState } from "react";
import Header from "../../../commons/header/header";
import Footer from "../../../commons/footer/footer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
// import "../../naac.css"; // Ensure you have a CSS file for styling
import image3 from "../../../../assests/images/background-learner.jpg";

const handleex11 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/EXTENEDPROFILE/1.1/description.pdf`,
    "_blank"
  );
};

const handleex21 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/EXTENDEDPROFILE/2.1/description.pdf`,
    "_blank"
  );
};

const handleex22 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/EXTENDEDPROFILE/2.2/description.pdf`,
    "_blank"
  );
};

const handleex31 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/EXTENDEDPROFILE/3.1/description.pdf`,
    "_blank"
  );
};

const handle121 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-1/1.2.1/description.pdf`,
    "_blank"
  );
};

const handle122 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-1/1.2.2/description.pdf`,
    "_blank"
  );
};

const handle132 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-1/1.3.2/description.pdf`,
    "_blank"
  );
};

const handle141 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-1/1.4.1/description.pdf`,
    "_blank"
  );
};

const handle211 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-2/2.1.1/description.pdf`,
    "_blank"
  );
};

const handle212 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-2/2.1.2/description.pdf`,
    "_blank"
  );
};

const handle221 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-2/2.2.1/description.pdf`,
    "_blank"
  );
};

const handle241 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-2/2.4.1/description.pdf`,
    "_blank"
  );
};

const handle242 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-2/2.4.2/description.pdf`,
    "_blank"
  );
};

const handle263 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-2/2.6.3/description.pdf`,
    "_blank"
  );
};

const handle311 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-3/3.1.1/description.pdf`,
    "_blank"
  );
};
const handle322 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-3/3.2.2/description.pdf`,
    "_blank"
  );
};
const handle331 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-3/3.3.1/description.pdf`,
    "_blank"
  );
};
const handle332 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-3/3.3.2/description.pdf`,
    "_blank"
  );
};
const handle343 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-3/3.4.3/description.pdf`,
    "_blank"
  );
};

const handle351 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-3/3.5.1/description.pdf`,
    "_blank"
  );
};

const handle412 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-4/4.1.2/description.pdf`,
    "_blank"
  );
};

const handle441 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-4/4.4.1/description.pdf`,
    "_blank"
  );
};

const handle432 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-4/4.3.2/description.pdf`,
    "_blank"
  );
};

const handle511 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-5/5.1.1/description.pdf`,
    "_blank"
  );
};

const handle512 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-5/5.1.2/description.pdf`,
    "_blank"
  );
};

const handle513 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-5/5.1.3/description.pdf`,
    "_blank"
  );
};

const handle514 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-5/5.1.4/description.pdf`,
    "_blank"
  );
};

const handle521 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-5/5.2.1/description.pdf`,
    "_blank"
  );
};

const handle522 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-5/5.2.2/description.pdf`,
    "_blank"
  );
};

const handle531 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-5/5.3.1/description.pdf`,
    "_blank"
  );
};

const handle532 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-5/5.3.2/description.pdf`,
    "_blank"
  );
};

const handle622 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-6/6.2.2/description.pdf`,
    "_blank"
  );
};

const handle632 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-6/6.3.2/description.pdf`,
    "_blank"
  );
};
const handle633 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-6/6.3.3/description.pdf`,
    "_blank"
  );
};
const handle652 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-6/6.5.2/description.pdf`,
    "_blank"
  );
};

const handle712 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-7/7.1.2/description.pdf`,
    "_blank"
  );
};

const handle713 = () => {
  window.open(
    `${process.env.PUBLIC_URL}/pdf/DVV/CRITERION-7/7.1.3/description.pdf`,
    "_blank"
  );
};

const NAAC = () => {
  const [activeTab, setActiveTab] = useState("tab0");

  const renderContent = () => {
    switch (activeTab) {
      case "tab0":
        return (
          <div>
            <h5>
              <span className="span-color-heading">EXTENDED PROFILE</span>
            </h5>

            <hr />
            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Link</strong>
                  </th>
                </tr>

                {/* Row 1 */}
                <tr>
                  <td>1.1</td>
                  <td>
                    <span>
                      Number of students year wise during the last five years
                    </span>
                  </td>
                  <td>
                    <button className="view-button" onClick={handleex11}>
                      {" "}
                      View
                    </button>
                  </td>
                </tr>

                {/* Row 2 */}
                <tr>
                  <td>2.1</td>
                  <td>
                    <span>
                      Number of teaching staff / full time teachers during the
                      last five years (Without repeat count):
                    </span>
                  </td>
                  <td>
                    <button className="view-button" onClick={handleex21}>
                      {" "}
                      View
                    </button>
                  </td>
                </tr>

                {/* New Row 3 */}
                <tr>
                  <td>2.2</td>
                  <td>
                    <span>
                      Number of teaching staff / full time teachers year wise
                      during the last five years
                    </span>
                  </td>
                  <td>
                    <button className="view-button" onClick={handleex22}>
                      {" "}
                      View
                    </button>
                  </td>
                </tr>

                {/* Row 4 */}
                <tr>
                  <td>3.1</td>
                  <td>
                    <span>
                      Expenditure excluding salary component year wise during
                      the last five years (INR in lakhs)
                    </span>
                  </td>
                  <td>
                    <button className="view-button" onClick={handleex31}>
                      {" "}
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <hr />
          </div>
        );
      case "tab1":
        return (
          <div>
            <h5>
              CRITERION 1 –{" "}
              <span className="span-color-heading">Curricular Aspects</span>
            </h5>
            <hr />

            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Link</strong>
                  </th>
                </tr>

                {/* Row 1 */}
                <tr>
                  <td>1.2.1</td>
                  <td>
                    <span>
                      Number of Certificate/Value added courses offered and
                      online courses of MOOCs, SWAYAM, NPTEL etc. (where the
                      students of the institution have enrolled and successfully
                      completed during the last five years)
                    </span>
                  </td>
                  <td>
                    <button className="view-button" onClick={handle121}>
                      {" "}
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 2 */}
                <tr>
                  <td>1.2.2</td>
                  <td>
                    <span>
                      Percentage of students enrolled in Certificate/ Value
                      added courses and also completed online courses of MOOCs,
                      SWAYAM, NPTEL etc. as against the total number of students
                      during the last five years
                      <br />
                      1.2.2.1. Number of students enrolled in Certificate/ Value
                      added courses and also completed online courses of MOOCs,
                      SWAYAM, NPTEL etc. as against the total number of students
                      during the last five year
                    </span>
                  </td>
                  <td>
                    <button className="view-button" onClick={handle122}>
                      {" "}
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 3 */}
                <tr>
                  <td>1.3.2</td>
                  <td>
                    <span>
                      Percentage of students undertaking project work/field
                      work/ internships (Data for the latest completed academic
                      year)
                      <br />
                      1.3.2.1. Number of students undertaking project work/field
                      work / internships
                    </span>
                  </td>
                  <td>
                    <button className="view-button" onClick={handle132}>
                      {" "}
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 4 */}
                <tr>
                  <td>1.4.1</td>
                  <td>
                    <span>
                      Institution obtains feedback on the academic performance
                      and ambience of the institution from various stakeholders,
                      such as Students, Teachers, Employers, Alumni etc. and
                      action taken report on the feedback is made available on
                      institutional website
                    </span>
                  </td>
                  <td>
                    <button className="view-button" onClick={handle141}>
                      {" "}
                      VIEW
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );

      case "tab2":
        return (
          <div>
            <h5>
              CRITERION 2 –{" "}
              <span className="span-color-heading">
                Teaching Learning and Evaluation
              </span>
            </h5>
            <hr />

            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Link</strong>
                  </th>
                </tr>

                {/* Row 1 */}
                <tr>
                  <td>2.1.1</td>
                  <td>
                    Enrolment percentage
                    <br />
                    2.1.1.1. Number of seats filled year wise during last five
                    years (Only first year admissions to be considered)
                    <br />
                    2.1.1.2. Number of sanctioned seats year wise during last
                    five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle211}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 2 */}
                <tr>
                  <td>2.1.2</td>
                  <td>
                    Percentage of seats filled against reserved categories (SC,
                    ST, OBC etc.) as per applicable reservation policy for the
                    first year admission during the last five years
                    <br />
                    2.1.2.1. Number of actual students admitted from the
                    reserved categories year wise during last five years
                    (Exclusive of supernumerary seats)
                    <br />
                    2.1.2.2. Number of seats earmarked for reserved category as
                    per GOI/ State Govt rule year wise during the last five
                    years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle212}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 3 */}
                <tr>
                  <td>2.2.1</td>
                  <td>
                    Student – Full time Teacher Ratio (Data for the latest
                    completed academic year)
                  </td>
                  <td>
                    <button className="view-button" onClick={handle221}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 4 */}
                <tr>
                  <td>2.4.1</td>
                  <td>
                    Percentage of full-time teachers against sanctioned posts
                    during the last five years
                    <br />
                    2.4.1.1. Number of sanctioned posts year wise during the
                    last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle241}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 5 */}
                <tr>
                  <td>2.4.2</td>
                  <td>
                    Percentage of full time teachers with NET/SET/SLET/ Ph.
                    D./D.Sc. / D.Litt./L.L.D. during the last five years
                    (consider only highest degree for count)
                    <br />
                    2.4.2.1. Number of full time teachers with NET/SET/SLET/Ph.
                    D./ D.Sc. / D.Litt./L.L.D year wise during the last five
                    years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle242}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 6 */}
                <tr>
                  <td>2.6.3</td>
                  <td>
                    Pass percentage of Students during last five years
                    (excluding backlog students)
                    <br />
                    2.6.3.1. Number of final year students who passed the
                    university examination year wise during the last five years
                    <br />
                    2.6.3.2. Number of final year students who appeared for the
                    university examination year-wise during the last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle263}>
                      VIEW
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );

      case "tab3":
        return (
          <div>
            <h5>
              CRITERION 3 –{" "}
              <span className="span-color-heading">
                Research, Innovations and Extension
              </span>
            </h5>
            <hr />

            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Link</strong>
                  </th>
                </tr>

                {/* Row 1 */}
                <tr>
                  <td>3.1.1</td>
                  <td>
                    Grants received from Government and non-governmental
                    agencies for research projects / endowments in the
                    institution during the last five years (INR in Lakhs)
                    <br />
                    3.1.1.1. Total Grants from Government and non-governmental
                    agencies for research projects / endowments in the
                    institution during the last five years (INR in Lakhs)
                  </td>
                  <td>
                    <button className="view-button" onClick={handle311}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 2 */}
                <tr>
                  <td>3.2.2</td>
                  <td>
                    Number of workshops/seminars/conferences including on
                    Research Methodology, Intellectual Property Rights (IPR) and
                    entrepreneurship conducted during the last five years
                    <br /> 3.2.2.1. Total number of
                    workshops/seminars/conferences including programs conducted
                    on Research Methodology, Intellectual Property Rights (IPR)
                    and entrepreneurship year wise during last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle322}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 3 */}
                <tr>
                  <td>3.3.1</td>
                  <td>
                    Number of research papers published per teacher in the
                    Journals notified on UGC care list during the last five
                    years
                    <br />
                    3.3.1.1. Number of research papers in the Journals notified
                    on UGC CARE list year wise during the last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle331}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 4 */}
                <tr>
                  <td>3.3.2</td>
                  <td>
                    Number of books and chapters in edited volumes/books
                    published and papers published in national/ international
                    conference proceedings per teacher during last five years
                    <br />
                    3.3.2.1. Total number of books and chapters in edited
                    volumes/books published and papers in national/
                    international conference proceedings year wise during last
                    five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle332}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 5 */}
                <tr>
                  <td>3.4.3</td>
                  <td>
                    Number of extension and outreach programs conducted by the
                    institution through organized forums including NSS/NCC with
                    involvement of community during the last five years.
                    <br />
                    3.4.3.1. Number of extension and outreach Programs conducted
                    in collaboration with industry, community, and Non-
                    Government Organizations through NSS/ NCC etc., year wise
                    during the last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle343}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 6 */}
                <tr>
                  <td>3.5.1</td>
                  <td>
                    Number of functional MoUs/linkages with institutions/
                    industries in India and abroad for internship, on-the-job
                    training, project work, student / faculty exchange and
                    collaborative research during the last five years.
                  </td>
                  <td>
                    <button className="view-button" onClick={handle351}>
                      VIEW
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case "tab4":
        return (
          <div>
            <h5>
              CRITERION 4 –{" "}
              <span className="span-color-heading">
                Infrastructure and Learning Resources
              </span>
            </h5>
            <hr />

            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Link</strong>
                  </th>
                </tr>

                {/* Row 1 */}
                <tr>
                  <td>4.1.2</td>
                  <td>
                    Percentage of expenditure for infrastructure development and
                    augmentation excluding salary during the last five years
                    <br />
                    4.1.2.1. Expenditure for infrastructure development and
                    augmentation, excluding salary year wise during last five
                    years (INR in lakhs)
                  </td>
                  <td>
                    <button className="view-button" onClick={handle412}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 2 */}
                <tr>
                  <td>4.3.2</td>
                  <td>
                    Student – Computer ratio (Data for the latest completed
                    academic year)
                    <br />
                    4.3.2.1. Number of computers available for students usage
                    during the latest completed academic year:
                  </td>
                  <td>
                    <button className="view-button" onClick={handle432}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 3 */}
                <tr>
                  <td>4.4.1</td>
                  <td>
                    Percentage expenditure incurred on maintenance of physical
                    facilities and academic support facilities excluding salary
                    component, during the last five years (INR in Lakhs)
                    <br />
                    4.4.1.1. Expenditure incurred on maintenance of
                    infrastructure (physical facilities and academic support
                    facilities) excluding salary component year wise during the
                    last five years (INR in lakhs)
                  </td>
                  <td>
                    <button className="view-button" onClick={handle441}>
                      VIEW
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case "tab5":
        return (
          <div>
            <h5>
              CRITERION 5 –{" "}
              <span className="span-color-heading">
                Student Support and Progression
              </span>
            </h5>
            <hr />

            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Link</strong>
                  </th>
                </tr>

                {/* Row 1 */}
                <tr>
                  <td>5.1.1</td>
                  <td>
                    Percentage of students benefited by scholarships and
                    freeships provided by the institution, government and
                    non-government bodies, industries, individuals,
                    philanthropists during the last five years
                    <br />
                    5.1.1.1. Number of students benefited by scholarships and
                    freeships provided by the institution, Government and
                    non-government bodies, industries, individuals,
                    philanthropists during the last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle511}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 2 */}
                <tr>
                  <td>5.1.2</td>
                  <td>
                    Following capacity development and skills enhancement
                    activities are organised for improving students’ capability
                    <ol>
                      <li>Soft skills</li>
                      <li>Language and communication skills</li>
                      <li>
                        Life skills (Yoga, physical fitness, health and hygiene)
                      </li>
                      <li>ICT/computing skills</li>
                    </ol>
                  </td>
                  <td>
                    <button className="view-button" onClick={handle512}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 3 */}
                <tr>
                  <td>5.1.3</td>
                  <td>
                    Percentage of students benefitted by guidance for
                    competitive examinations and career counseling offered by
                    the Institution during the last five years
                    <br /> 5.1.3.1. Number of students benefitted by guidance
                    for competitive examinations and career counselling offered
                    by the institution year wise during last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle513}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 4 */}
                <tr>
                  <td>5.1.4</td>
                  <td>
                    The institution adopts the following for redressal of
                    student grievances including sexual harassment and ragging
                    cases
                    <ol>
                      <li>
                        Implementation of guidelines of statutory/regulatory
                        bodies
                      </li>
                      <li>
                        Organisation wide awareness and undertakings on policies
                        with zero tolerance
                      </li>
                      <li>
                        Mechanisms for submission of online/offline students’
                        grievances
                      </li>
                      <li>
                        Timely redressal of the grievances through appropriate
                        committees
                      </li>
                    </ol>
                  </td>
                  <td>
                    <button className="view-button" onClick={handle514}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 5 */}
                <tr>
                  <td>5.2.1</td>
                  <td>
                    Percentage of placement of outgoing students and students
                    progressing to higher education during the last five years
                    <br /> 5.2.1.1. Number of outgoing students placed and / or
                    progressed to higher education year wise during the last
                    five years
                    <br /> 5.2.1.2. Number of outgoing students year wise during
                    the last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle521}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 6 */}
                <tr>
                  <td>5.2.2</td>
                  <td>
                    Percentage of students qualifying in state/national/
                    international level examinations during the last five years
                    <br />
                    5.2.2.1. Number of students qualifying in state/ national/
                    international level examinations year wise during last five
                    years (eg: IIT/JAM/NET/SLET/GATE/GMAT/GPAT/CLAT/CAT/
                    GRE/TOEFL/ IELTS/Civil Services/State government
                    examinations etc.)
                  </td>
                  <td>
                    <button className="view-button" onClick={handle522}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 7 */}
                <tr>
                  <td>5.3.1</td>
                  <td>
                    Number of awards/medals for outstanding performance in
                    sports/ cultural activities at University / state/ national
                    / international level (award for a team event should be
                    counted as one) during the last five years
                    <br /> 5.3.1.1. Number of awards/medals for outstanding
                    performance in sports/cultural activities at
                    national/international level (award for a team event should
                    be counted as one) year wise during the last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle531}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 8 */}
                <tr>
                  <td>5.3.2</td>
                  <td>
                    Average number of sports and cultural programs in which
                    students of the Institution participated during last five
                    years (organised by the institution/other institutions)
                    <br />
                    5.3.2.1. Number of sports and cultural programs in which
                    students of the Institution participated year wise during
                    last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle532}>
                      VIEW
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case "tab6":
        return (
          <div>
            <h5>
              CRITERION 6 –{" "}
              <span className="span-color-heading">
                Governance, Leadership and Management
              </span>
            </h5>
            <hr />

            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Link</strong>
                  </th>
                </tr>

                {/* Row 1 */}
                <tr>
                  <td>6.2.2</td>
                  <td>
                    Institution implements e-governance in its operations:
                    <ol>
                      <li>Administration</li>
                      <li>Finance and Accounts</li>
                      <li>Student Admission and Support</li>
                      <li>Examinations</li>
                    </ol>
                  </td>
                  <td>
                    <button className="view-button" onClick={handle622}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 2 */}
                <tr>
                  <td>6.3.2</td>
                  <td>
                    Percentage of teachers provided with financial support to
                    attend conferences/workshops and towards membership fee of
                    professional bodies during the last five years
                    <br /> 6.3.2.1. Number of teachers provided with financial
                    support to attend conferences/workshops and towards
                    membership fee of professional bodies year wise during the
                    last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle632}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 3 */}
                <tr>
                  <td>6.3.3</td>
                  <td>
                    Percentage of teaching and non-teaching staff participating
                    in Faculty development Programmes (FDP), Management
                    Development Programmes (MDPs) professional development
                    /administrative training programs during the last five years
                    <br />
                    6.3.3.1. Total number of teaching and non-teaching staff
                    participating in Faculty development Programmes (FDP),
                    Management Development Programmes (MDPs) professional
                    development /administrative training programs during the
                    last five years
                    <br />
                    6.3.3.2. Number of non-teaching staff year wise during the
                    last five years
                  </td>
                  <td>
                    <button className="view-button" onClick={handle633}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 4 */}
                <tr>
                  <td>6.5.2</td>
                  <td>
                    Quality assurance initiatives of the institution include:
                    <ol>
                      <li>
                        Regular meeting of Internal Quality Assurance Cell
                        (IQAC); quality improvement initiatives identified and
                        implemented
                      </li>
                      <li>
                        Academic and Administrative Audit (AAA) and follow-up
                        action taken
                      </li>
                      <li>
                        Collaborative quality initiatives with other
                        institution(s)
                      </li>
                      <li>
                        Participation in NIRF and other recognized rankings
                      </li>
                      <li>
                        Any other quality audit/accreditation recognized by
                        state, national or international agencies such as NAAC,
                        NBA etc.
                      </li>
                    </ol>
                  </td>
                  <td>
                    <button className="view-button" onClick={handle652}>
                      VIEW
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );

      case "tab7":
        return (
          <div>
            <h5>
              CRITERION 7 –{" "}
              <span className="span-color-heading">
                Institutional Values and Best Practices
              </span>
            </h5>
            <hr />

            <table className="criteria-table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    <strong>Metric No.</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Link</strong>
                  </th>
                </tr>

                {/* Row 1 */}
                <tr>
                  <td>7.1.2</td>
                  <td>
                    The Institution has facilities and initiatives for:
                    <ol>
                      <li>
                        Alternate sources of energy and energy conservation
                        measures
                      </li>
                      <li>
                        Management of the various types of degradable and
                        non-degradable waste
                      </li>
                      <li>Water conservation</li>
                      <li>Green campus initiatives</li>
                      <li>Disabled-friendly, barrier-free environment</li>
                    </ol>
                  </td>
                  <td>
                    <button className="view-button" onClick={handle712}>
                      VIEW
                    </button>
                  </td>
                </tr>

                {/* Row 2 */}
                <tr>
                  <td>7.1.3</td>
                  <td>
                    Quality audits on environment and energy regularly
                    undertaken by the Institution. The institutional environment
                    and energy initiatives are confirmed through the following:
                    <ol>
                      <li>Green audit / Environment audit</li>
                      <li>Energy audit</li>
                      <li>Clean and green campus initiatives</li>
                      <li>
                        Beyond the campus environmental promotion and
                        sustainability activities
                      </li>
                    </ol>
                  </td>
                  <td>
                    <button className="view-button" onClick={handle713}>
                      VIEW
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      default:
        return <p>Content for Tab 0</p>;
    }
  };

  return (
    <>
      <Header />
      <div className="image-section">
        <img src={image3} alt="Gallery" className="department-image" />
        <div className="overlay-text">SSR - DVV Clarifications</div>
      </div>
      <div className="naac-container">
        <div className="tabs">
          <button
            className={`tab-button ${activeTab === "tab0" ? "active" : ""}`}
            onClick={() => setActiveTab("tab0")}
          >
            EXTENDED PROFILE
          </button>
          <button
            className={`tab-button ${activeTab === "tab1" ? "active" : ""}`}
            onClick={() => setActiveTab("tab1")}
          >
            CRITERION 1
          </button>
          <button
            className={`tab-button ${activeTab === "tab2" ? "active" : ""}`}
            onClick={() => setActiveTab("tab2")}
          >
            CRITERION 2
          </button>
          <button
            className={`tab-button ${activeTab === "tab3" ? "active" : ""}`}
            onClick={() => setActiveTab("tab3")}
          >
            CRITERION 3
          </button>
          <button
            className={`tab-button ${activeTab === "tab4" ? "active" : ""}`}
            onClick={() => setActiveTab("tab4")}
          >
            CRITERION 4
          </button>
          <button
            className={`tab-button ${activeTab === "tab5" ? "active" : ""}`}
            onClick={() => setActiveTab("tab5")}
          >
            CRITERION 5
          </button>
          <button
            className={`tab-button ${activeTab === "tab6" ? "active" : ""}`}
            onClick={() => setActiveTab("tab6")}
          >
            CRITERION 6
          </button>
          <button
            className={`tab-button ${activeTab === "tab7" ? "active" : ""}`}
            onClick={() => setActiveTab("tab7")}
          >
            CRITERION 7
          </button>
        </div>
        <div className="content-naac">{renderContent()}</div>
      </div>
      <Footer />
    </>
  );
};

export default NAAC;
