import React, { useState, useEffect } from "react";
import Header from "../../../../commons/header/header";
import Footer from "../../../../commons/footer/footer";
import {
  Paper,
  Tabs,
  Tab,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./agri.css"; // Ensure you have a CSS file for styling
import image3 from "../../../../../assests/images/agri-bg.jpg";

// TabPanel component to display content for each tab
const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Agri = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="image-section">
        <img src={image3} alt="Department" className="department-image" />
        <div className="overlay-text">B.Tech. - AGRICULTURAL ENGINEERING</div>
      </div>
      <div className="department-details-content">
        <Paper elevation={3} className="department-details-paper">
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="department tabs"
            centered
          >
            <Tab label="About Department" />
            <Tab label="Faculty Details" />
            <Tab label="PEO / PO / PSO" />
            <Tab label="Lab Facilities" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {/* About Department content */}

            <Typography variant="h6" gutterBottom>
              About Agriculture Engineering Department
            </Typography>
            <p>
            One of the best B.Tech Agriculture colleges in Coimbatore is the Department of Agriculture Engineering at Asian college of Engineering and  Technology. The department was founded in 2021 and offers B.Tech program in Agricultural Engineering. Its main goals are to empower students with real-world knowledge and to help them overcome real-world problems that arise on small landholdings. The department offers training in a range of Agricultural Engineering topics, such as tractor, power tiller, sprayer, and duster repairs and maintenance, as well as the design and implementation of drip irrigation systems for fruits, vegetables, and millets. With state-of-the-art facilities and a committed faculty, the college offers students a thorough education in a range of engineering specialties, including agriculture engineering. Asian College of Engineering and Technology can assist you in reaching your objectives, regardless of whether you're interested in a career in agricultural engineering or any engineering discipline.
            </p>
            {/* <Typography variant="h6" gutterBottom>
              HOD: Mrs R. SUJITHA
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              <strong>Assistant Professor &amp; Head</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              Greetings from the Department of Information Technology at Christ
              The King Engineering College. It gives me immense pleasure to lead
              the Department of Information Technology which is striving towards
              the goal of providing innovative and quality education to the
              students to achieve academic excellence.
            </Typography>
            <Typography variant="body1" paragraph>
              The motto of the department is to provide quality technical
              education to make the students industry-ready. Our goal is to
              ensure that our engineering graduates are well prepared to play
              the roles of problem solvers, project leaders, entrepreneurs, and
              above all ethical citizens of a global society.
            </Typography>
            <Typography variant="body1" paragraph>
              The excellent infrastructure, dynamic teaching staff along with
              training and placement cell ensure a bright future for the
              students. The greatest asset of the department is its highly
              motivated and learned faculty.
            </Typography>
            <Typography variant="body1" paragraph>
              I am very confident that our students will emerge as assets not
              only to the institution and to the organization they join, but to
              society at large. With this, I would like to congratulate the
              students and staff for their brilliant achievements and wish them
              a great future.
            </Typography> */}
            <Typography variant="h6" gutterBottom>
              Vision
            </Typography>
            <Typography variant="body1" paragraph>
            Utilizing science and technology to maintain food, nutrition, livelihood security, and the management of natural resources.</Typography>
            <Typography variant="h6" gutterBottom>
              Mission
            </Typography>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                Connecting front-line extension programs, higher education, and agricultural research and technology with institutional, infrastructural, and policy support for the sustainable growth of agriculture. </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                To help farmers make more money, carry out research and offer consulting services on technical solutions and mechanization in agriculture. </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                To involve creating accessible technology, setting up specialized employment facilities, and encouraging young people from farming to pursue their business dreams. </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                  To Establish innovative facilities in order to upgrade skills in an interdisciplinary approach to solving issues in agriculture. 
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                Ensuring the welfare of farmers, ecosystems, and future generations through the promotion of moral principles and sustainable ethical practices
                </Typography>
              </li>
            </ul>

          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {/* Faculty Details content */}
            <Typography variant="h6" gutterBottom>
              Faculty Details
            </Typography>
            <TableContainer className="table-container">
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-head-cell">S.No.</TableCell>
                    <TableCell className="table-head-cell">
                      Name of the Members
                    </TableCell>
                    <TableCell className="table-head-cell">
                      Designation
                    </TableCell>
                    <TableCell className="table-head-cell">
                      Qualification
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">1</TableCell>
                    <TableCell className="table-cell">
                      Mr. Rathinavel S
                    </TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">B.Tech, M.Tech</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">2</TableCell>
                    <TableCell className="table-cell">Mrs. Nivedha B</TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">B.Sc, M.Sc</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">3</TableCell>
                    <TableCell className="table-cell">Mrs. Meera M P</TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">B.E, M.E</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">4</TableCell>
                    <TableCell className="table-cell">Mr. Karthik B</TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">B.E, M.E</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">5</TableCell>
                    <TableCell className="table-cell">
                      Mr. Dhivakaran S
                    </TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">B.E, M.E</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">6</TableCell>
                    <TableCell className="table-cell">
                      Mrs. Nancy Priya S
                    </TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">B.E, M.E</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">7</TableCell>
                    <TableCell className="table-cell">
                      Dr. Ezra John J
                    </TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc, M.Sc, Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">8</TableCell>
                    <TableCell className="table-cell">Mrs. Saritha V</TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">B.Sc, M.Sc</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Typography variant="h6" gutterBottom>
              Program Educational Objectives (PEOs):
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PEO1</TableCell>
                  <TableCell>
                    To provide the students with a strong foundation in the
                    required sciences in order to pursue studies in Electronics
                    and Communication Engineering.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PEO2</TableCell>
                  <TableCell>
                    To gain adequate knowledge to become good professionals in
                    electronic and communication engineering associated
                    industries, higher education, and research.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom>
              Program Outcomes (POs):
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PO1</TableCell>
                  <TableCell>
                    Apply the knowledge of mathematics, science, engineering
                    fundamentals, and an engineering specialization to the
                    solution of complex engineering problems.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO2</TableCell>
                  <TableCell>
                    Identify, formulate, review research literature, and analyze
                    complex engineering problems reaching substantiated
                    conclusions using first principles of mathematics, natural
                    sciences, and engineering sciences.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom>
              Program Specific Outcomes (PSOs):
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PSO1</TableCell>
                  <TableCell>
                    Design, develop, and analyze electronic systems through the
                    application of relevant electronics, mathematics, and
                    engineering principles.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {/* Lab Facilities content */}
            <Typography variant="h6" gutterBottom>
              Lab Facilities
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>1. Data Structures Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The Data Structure lab is precise and equipped with high-end Wipro
              and Lenovo desktop machines for data structure laboratory. This
              lab has 34 Intel Core i5 processor machines and Pentium dual-core
              processor machines with 8 GB RAM, 500 HDD, Windows 10 Pro OS,
              exclusively dedicated for faculty members and students to take up
              research and strengthen their knowledge in data structure.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>2. Programming Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The lab is designed to enhance the programming proficiency of
              students in languages such as C, Python++, JAVA, and web
              technology utilizing IDEs like Sublime, Visual Basic, and
              Notepad++.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>3. Mobile Application Development</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              Our Mobile Application Development Lab, outfitted with Intel Core
              i5 desktop PCs, stands as a vital resource within our facilities.
              It acts as a central hub where students can enhance their skills
              in diverse programming languages like C, C++, Java, Python, and
              database management systems. With 8GB of RAM, the lab is optimized
              for Android application development. Additionally, it accommodates
              various hypervisors such as Oracle Virtual Box, Xen, and
              OpenStack. The lab's infrastructure seamlessly integrates
              high-speed internet connectivity, ensuring students have
              uninterrupted access to online resources.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>4. Data Science & Cloud Computing Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The Data Science and Cloud Computing lab is equipped with Samsung
              & Wipro HP desktop machines, designed specifically for data
              analytics and cloud projects. It houses 31 Wipro Intel i5
              machines, each with 16 GB RAM & 5 Samsung, exclusively for faculty
              and student research in Data Science. The lab provides hands-on
              experience, supports international certifications, and helps
              students secure industry jobs.
            </Typography>
          </TabPanel>
        </Paper>
      </div>
      <Footer />
    </>
  );
};

export default Agri;
