import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navbarRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdown = (name) => {
    setActiveDropdown(activeDropdown === name ? null : name);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCrossCuttingClick = () => {
    window.open(`${process.env.PUBLIC_URL}/pdf/CROSSCUTTING.pdf`, "_blank");
  };

  return (
    <nav className="navbar" ref={navbarRef}>
      <div className="hamburger" onClick={toggleMenu}>
        &#9776;
      </div>
      <ul className={isOpen ? "nav-links open" : "nav-links"}>
        <li>
          <Link to="/" onClick={() => setActiveDropdown(null)}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/aboutus" onClick={() => setActiveDropdown(null)}>
            About
          </Link>
        </li>
        <li className="dropdown" onClick={() => handleDropdown("academics")}>
          <a href="#academics">Academics</a>
          <ul
            className={
              activeDropdown === "academics"
                ? "dropdown-content open"
                : "dropdown-content"
            }
          >
            <li className="dropdown-item">
              <a href="#programs" onClick={(e) => e.preventDefault()}>
                Programs
              </a>
              <ul
                className={
                  activeDropdown === "programs"
                    ? "dropdown-subcontent open"
                    : "dropdown-subcontent"
                }
              >
                <li>
                  <Link
                    to="/department-details/cse"
                    onClick={() => setActiveDropdown(null)}
                  >
                    Computer Science and Engineering
                  </Link>
                </li>
                <li>
                  <Link
                    to="/department-details/eee"
                    onClick={() => setActiveDropdown(null)}
                  >
                    Electrical and Electronics Engineering
                  </Link>
                </li>
                <li>
                  <Link
                    to="/department-details/mech"
                    onClick={() => setActiveDropdown(null)}
                  >
                    Mechanical Engineering
                  </Link>
                </li>
                <li>
                  <Link
                    to="/department-details/it"
                    onClick={() => setActiveDropdown(null)}
                  >
                    Information Technology
                  </Link>
                </li>
                <li>
                  <Link
                    to="/department-details/biomedical"
                    onClick={() => setActiveDropdown(null)}
                  >
                    Bio Medical Engineering
                  </Link>
                </li>
                <li>
                  <Link
                    to="/department-details/agri"
                    onClick={() => setActiveDropdown(null)}
                  >
                    Agriculture Engineering
                  </Link>
                </li>
                <li>
                  <Link
                    to="/department-details/aids"
                    onClick={() => setActiveDropdown(null)}
                  >
                    Artificial Intelligence and Data Science
                  </Link>
                </li>

                <li>
                  <Link
                    to="/department-details/scienc-and-humanities"
                    onClick={() => setActiveDropdown(null)}
                  >
                    Science And Humanities
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className="dropdown-item"
              onClick={() => handleDropdown("regulations")}
            >
              <a href="#regulations" onClick={(e) => e.preventDefault()}>
                Regulations
              </a>
              <ul
                className={
                  activeDropdown === "regulations"
                    ? "dropdown-subcontent open"
                    : "dropdown-subcontent"
                }
              >
                <li>
                  <Link
                    onClick={() =>
                      window.open(
                        `${process.env.PUBLIC_URL}/pdf/2017-AU-REG.pdf`,
                        "_blank"
                      )
                    }
                  >
                    2017 - AU Regulation
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() =>
                      window.open(
                        `${process.env.PUBLIC_URL}/pdf/2021-AU-REG.pdf`,
                        "_blank"
                      )
                    }
                  >
                    2021 - AU Regulation
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link onClick={handleCrossCuttingClick}>Cross Cutting</Link>
            </li>
          </ul>
        </li>
        <li className="dropdown" onClick={() => handleDropdown("admission")}>
          <a href="#admission" onClick={(e) => e.preventDefault()}>
            Admission
          </a>
          <ul
            className={
              activeDropdown === "admission"
                ? "dropdown-content open"
                : "dropdown-content"
            }
          >
            <li>
              <Link
                to="/admission-contact"
                onClick={() => setActiveDropdown(null)}
              >
                Admission Contact
              </Link>
            </li>
            <li>
              <Link
                to="/admission-form"
                onClick={() => setActiveDropdown(null)}
              >
                Admission Form
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/infrastructure" onClick={() => setActiveDropdown(null)}>
            Infrastructure
          </Link>
        </li>
        <li className="dropdown" onClick={() => handleDropdown("research")}>
          <a href="#research" onClick={(e) => e.preventDefault()}>
            Research
          </a>
          <ul
            className={
              activeDropdown === "research"
                ? "dropdown-content open"
                : "dropdown-content"
            }
          >
            <li>
              <Link
                to="https://acetcbe.in/pdf/CRITERION-3/3.3.1/Description.pdf"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setActiveDropdown(null)}
              >
                Publications
              </Link>
            </li>
            <li>
              <Link
                to="https://acetcbe.in/pdf/CRITERION-3/3.5.1/Description.pdf"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setActiveDropdown(null)}
              >
                MoUs
              </Link>
            </li>
            <li>
              <Link
                to="https://acetcbe.in/pdf/CRITERION-2/2.4/2.4.2/FullTimeTeachers.pdf"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setActiveDropdown(null)}
              >
                List of PhD Holders
              </Link>
            </li>
          </ul>
        </li>
        <li className="dropdown" onClick={() => handleDropdown("committee")}>
          <a href="#committee" onClick={(e) => e.preventDefault()}>
            Committee
          </a>
          <ul
            className={
              activeDropdown === "committee"
                ? "dropdown-content open"
                : "dropdown-content"
            }
          >
            <li>
              <Link to="/icc" onClick={() => setActiveDropdown(null)}>
                Internal Complaint Committee
              </Link>
            </li>
            <li>
              <Link to="/grc" onClick={() => setActiveDropdown(null)}>
                Grievance Redressel Committee
              </Link>
            </li>
            <li>
              <Link to="/sc-st" onClick={() => setActiveDropdown(null)}>
                SC / ST Committee
              </Link>
            </li>
            <li>
              <Link to="/obc-cell" onClick={() => setActiveDropdown(null)}>
                OBC Cell
              </Link>
            </li>
            <li>
              <Link to="/anti-ragging" onClick={() => setActiveDropdown(null)}>
                Anti - Ragging Committee
              </Link>
            </li>
          </ul>
        </li>

        {/* <li className="dropdown" onClick={() => handleDropdown("placement")}>
          <a href="#placement" onClick={(e) => e.preventDefault()}>
            Placements
          </a>
          <ul
            className={
              activeDropdown === "placement"
                ? "dropdown-content open"
                : "dropdown-content"
            }
          >
            <li>
              <Link to="/placement" onClick={() => setActiveDropdown(null)}>
                Placement Training
              </Link>
            </li>

            <li>
              <Link to="/capacity_building" onClick={() => setActiveDropdown(null)}>
                Capacity Building
              </Link>
            </li>


          </ul>
        </li> */}
        <li>
          <Link to="/placement" onClick={() => setActiveDropdown(null)}>
            Placements
          </Link>
        </li>
        <li className="dropdown" onClick={() => handleDropdown("grievance")}>
          <a href="#grievance" onClick={(e) => e.preventDefault()}>
            Grievance
          </a>
          <ul
            className={
              activeDropdown === "grievance"
                ? "dropdown-content open"
                : "dropdown-content"
            }
          >
            <li>
              <Link
                onClick={() =>
                  window.open(`https://forms.gle/Z1VHxMygdG6DpB527`, "_blank")
                }
              >
                Anti-Ragging
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  window.open(`https://forms.gle/JxEK1XN8ic8LLx2k7`, "_blank")
                }
              >
                Grievance Redressel
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  window.open(`https://forms.gle/9FTPhPFWq8gav2sX6`, "_blank")
                }
              >
                Internal Complaints
              </Link>
            </li>
          </ul>
        </li>
        {/* 
        <li className="dropdown" onClick={() => handleDropdown("feedback")}>
          <a href="#feedback" onClick={(e) => e.preventDefault()}>
            Feedback
          </a>
          <ul
            className={
              activeDropdown === "feedback"
                ? "dropdown-content open"
                : "dropdown-content"
            }
          >
            <li>
              <Link
                to="https://acetcbe.kredovoiceout.in/#/el/10000009"
                onClick={() => setActiveDropdown(null)}
              >
                Employers Feedback
              </Link>
            </li>
            <li>
              <Link
                to="https://acetcbe.kredovoiceout.in/#/el/10000008"
                onClick={() => setActiveDropdown(null)}
              >
                Alumni Feedback
              </Link>
            </li>

            <li>
              <Link
                to="https://acetcbe.kredovoiceout.in/#/el/10000007"
                onClick={() => setActiveDropdown(null)}
              >
                Teachers Feedback
              </Link>
            </li>

            <li>
              <Link
                to="https://acetcbe.kredovoiceout.in/#/el/10000006"
                onClick={() => setActiveDropdown(null)}
              >
                Student Feedback
              </Link>
            </li>


          </ul>
        </li> */}

        <li className="dropdown" onClick={() => handleDropdown("feedback")}>
          <a href="#feedback" onClick={(e) => e.preventDefault()}>
            Feedback
          </a>
          <ul
            className={
              activeDropdown === "feedback"
                ? "dropdown-content open"
                : "dropdown-content"
            }
          >
            <li>
              <Link
                to="https://acetcbe.kredovoiceout.in/#/el/10000009"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setActiveDropdown(null)}
              >
                Employers Feedback
              </Link>
            </li>
            <li>
              <Link
                to="https://acetcbe.kredovoiceout.in/#/el/10000008"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setActiveDropdown(null)}
              >
                Alumini Feedback
              </Link>
            </li>
            <li>
              <Link
                to="https://acetcbe.kredovoiceout.in/#/el/10000007"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setActiveDropdown(null)}
              >
                Teachers Feedback
              </Link>
            </li>
            <li>
              <Link
                to="https://acetcbe.kredovoiceout.in/#/el/10000006"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setActiveDropdown(null)}
              >
                Student Feedback
              </Link>
            </li>
            <li>
              <Link
                to="https://acetcbe.in/pdf/CRITERION-1/1.4.1/FeedbackAnalysis.pdf"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setActiveDropdown(null)}
              >
                Analysis Report
              </Link>
            </li>

            <li>
              <Link
                to="https://acetcbe.in/pdf/CRITERION-1/1.4.1/ActiontakenReport.pdf"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setActiveDropdown(null)}
              >
                Action Taken Report
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/gallery" onClick={() => setActiveDropdown(null)}>
            Gallery
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={() => setActiveDropdown(null)}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
