// src/components/header/Header.js
import React from "react";
import Header from "../../commons/header/header";
import About from "../../commons/homeAbout/about";
import Footer from "../../commons/footer/footer";
import News from "../../commons/news/news";
import Count from "../../commons/counts/count";
import Department from "../../commons/homeDepartments/departments";
import Section from "../../commons/header/sectionimage";
import Notification from "../../commons/notification/notification";
// import NotificationPopup from "../../commons/alert/alert";
import './home.css'
const Home = () => {
  // const [popupOpen, setPopupOpen] = useState(false);

  // useEffect(() => {
  //   setPopupOpen(true); // Open the popup when the component mounts
  // }, []);

  // const handlePopupClose = () => {
  //   setPopupOpen(false);
  // };

  return (
    <>
      {/* <NotificationPopup open={popupOpen} handleClose={handlePopupClose} /> */}
      <Header /> 
      
      <div className="home">
      <Section />
      <Notification />
      <div className="my-4">
        <About />
      </div>
      <div className="my-4">
        <News />
      </div>
      <div className="my-4">
        <Count />
      </div>
      <div className="my-4">
        <Department />
      </div>
      <div className="my-4">
        <Footer />
      </div>
      </div>
    </>
  );
};

export default Home;
