import React, { useState } from 'react';
import { Container, Grid, Typography, TextField, Button, Box, Link, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import Header from '../../commons/header/header';
import Footer from '../../commons/footer/footer';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import './contact.css'; // Import the CSS file

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    contactNumber: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form data
    if (!formData.name || !formData.email || !formData.subject || !formData.contactNumber || !formData.message) {
      Swal.fire({
        icon: 'warning',
        title: 'Missing Fields',
        text: 'Please fill in all fields before submitting.',
      });
      return;
    }

    emailjs.send('service_qe2ig03', 'template_4mkspmj', formData, 'GCDl38xrm0sQpFXlz')
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'Submitted!',
          text: 'Your message has been sent successfully.',
        });
        // Clear form data
        setFormData({
          name: '',
          email: '',
          subject: '',
          contactNumber: '',
          message: '',
        });
      })
      .catch(error => {
        const errorMessage = error?.message || 'An unexpected error occurred. Please try again later.';
        if (errorMessage.includes('Network Error')) {
          Swal.fire({
            icon: 'error',
            title: 'Network Error',
            text: 'There was a network error. Please try again later.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Something went wrong: ${errorMessage}`,
          });
        }
      });
  };

  return (
    <>
      <Header />
      <Container className="contact-container">
        <Grid container spacing={4}>
          {/* Contact Details */}
          <Grid item xs={12} md={6}>
            <Box className="contact-box contact-details">
              <Typography variant="h6" gutterBottom>Contact Details</Typography>
              <Typography>
                <strong>Dr. P. Eswaramoorthi M.E, Ph.D., (Principal)</strong>
                <br />
                <Link href="tel:+7708069402" underline="none">Contact : 7708069402</Link>
                <br /><br />
                <strong>Dr. K. M. Savithri M.E, PhD, MISTE., (Vice Principal)</strong>
                <br />
                <Link href="tel:+9894582809" underline="none">Contact : 9894582809</Link>
                <br /><br />
                <strong>Mr. T. Babu Senthil Kumar M.E., MBA., PGDMM., MISTE.,</strong>
                <br />
                Administrative Officer : <Link href="tel:+8754955999" underline="none">8754955999</Link>
                <br /><br />
                <strong>Mr. S. Prabu B.E.,</strong>
                <br />
                C.E.O : <Link href="tel:+8973505785" underline="none">8973505785</Link>
                <br /><br />
                <strong>Mr. S. Arjun B.E.,</strong>
                <br />
                P.R.O : <Link href="tel:+9600402530" underline="none">9600402530</Link>
              </Typography>
              <Box mt={4}>
                <Typography variant="h6">Address</Typography>
                <Typography>
                  Asian College of Engineering and Technology
                  <br />
                  294/2, Asian College Road, Kovilpalayam to Thudiyalur Road,
                  <br />
                  Near Saravanampatti, Coimbatore 641 110,
                  <br />
                  Tamil Nadu. INDIA.
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Contact Form */}
          <Grid item xs={12} md={6}>
            <Box className="contact-box contact-form">
              <Typography variant="h6" gutterBottom>Contact Form</Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                />
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel id="subject-label">Course</InputLabel>
                  <Select
                    labelId="subject-label"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    label="Course"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Adjust max height to fit on small screens
                          width: 'auto',
                        },
                      },
                      classes: {
                        list: 'dropdown-list',
                      },
                    }}
                  >
                    <MenuItem value="B.E. - COMPUTER SCIENCE AND ENGINEERING">B.E. - COMPUTER SCIENCE AND ENGINEERING</MenuItem>
                    <MenuItem value="B.E. – ELECTRICAL AND ELECTRONICS ENGINEERING">B.E. – ELECTRICAL AND ELECTRONICS ENGINEERING</MenuItem>
                    <MenuItem value="B.E.- MECHANICAL ENGINEERING">B.E.- MECHANICAL ENGINEERING</MenuItem>
                    <MenuItem value="B.E. – BIOMEDICAL ENGINEERING">B.E. – BIOMEDICAL ENGINEERING</MenuItem>
                    <MenuItem value="B.Tech. - AGRICULTURAL ENGINEERING">B.Tech. - AGRICULTURAL ENGINEERING</MenuItem>
                    <MenuItem value="B.Tech - ARTIFICIAL INTELLIGENCE AND DATA SCIENCE">B.Tech - ARTIFICIAL INTELLIGENCE AND DATA SCIENCE</MenuItem>
                    <MenuItem value="B.Tech - INFORMATION TECHNOLOGY">B.Tech - INFORMATION TECHNOLOGY</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Contact Number"
                  name="contactNumber"
                  type="tel"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Submit
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
