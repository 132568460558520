import React from "react";
import Slider from "react-slick";
import "./department.css"; // Ensure you have the correct path
import agri from "../../../assests/images/agri.jpg";
import aids from "../../../assests/images/aids.jpg";
import it from "../../../assests/images/it.jpg";
import cse from "../../../assests/images/cse.jpg";
import eee from "../../../assests/images/eee.jpg";
import mech from "../../../assests/images/mech.jpg";
import biomedical from "../../../assests/images/bio-medical.jpeg";

import { Link } from 'react-router-dom';

const departments = [
  {
    id: 1,
    name: "B.E. - COMPUTER SCIENCE AND ENGINEERING",
    shortname: "cse",
    image: cse,
  },
  {
    id: 2,
    name: "B.E. – ELECTRICAL AND ELECTRONICS ENGINEERING",
    shortname: "eee",
    image: eee,
  },
  {
    id: 3,
    name: "B.E.- MECHANICAL ENGINEERING",
    shortname: "mech",
    image: mech,
  },
  {
    id: 4,
    name: "B.E. – BIOMEDICAL ENGINEERING",
    shortname: "biomedical",
    image: biomedical,
  },
  {
    id: 5,
    name: "B.Tech. - AGRICULTURAL ENGINEERING",
    shortname: "agri",
    image: agri,
  },
  {
    id: 6,
    name: "B.Tech - ARTIFICIAL INTELLIGENCE AND DATA SCIENCE",
    shortname: "aids",
    image: aids,
  },
  {
    id: 7,
    name: "B.Tech - INFORMATION TECHNOLOGY",
    shortname: "it",
    image: it,
  },
  // Add other department data here
];

const DepartmentCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="section-title mb-50">
        <h2>Our Departments</h2>
      </div>
      <Slider {...settings}>
        {departments.map((department) => (
          <div key={department.id} className="single-course test-shine">
            <div className="course-content">
              <div className="course-img">
                <Link to={`/department-details/${department.shortname}`}>
                  <img
                    className="animated ic"
                    src={department.image}
                    alt={department.name}
                  />
                </Link>
              </div>
              <h4 className="text-center">
                <Link to={`/department-details/${department.shortname}`}>
                  {department.name}
                </Link>
              </h4>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default DepartmentCarousel;

// Custom Arrow Components
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: '#01366b' }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: '#01366b' }}
      onClick={onClick}
    />
  );
};