import React from 'react';
import { Carousel } from 'react-bootstrap';
import './news.css'; // Import the CSS file
import image3 from "../../../assests/images/3.jpg";

const LatestEventsCarousel = () => {
    const events = [
        {
            date: '2023-04-21',
            title: 'Seminar on Natural language processing with python implementation',
            department: 'Computer Science and Engineering',
            imageSrc: image3,
            isNew: true,
            rating: 5
        },
        {
            date: '2023-04-21',
            title: 'Seminar on Natural language processing with python implementation',
            department: 'Computer Science and Engineering',
            imageSrc: image3,
            isNew: true,
            rating: 5
        },
        {
            date: '2023-04-21',
            title: 'Seminar on Natural language processing with python implementation',
            department: 'Computer Science and Engineering',
            imageSrc: image3,
            isNew: true,
            rating: 5
        },
        {
            date: '2023-04-21',
            title: 'Seminar on Natural language processing with python implementation',
            department: 'Computer Science and Engineering',
            imageSrc: image3,
            isNew: true,
            rating: 5
        },
        {
            date: '2023-04-21',
            title: 'Seminar on Natural language processing with python implementation',
            department: 'Computer Science and Engineering',
            imageSrc: image3,
            isNew: true,
            rating: 5
        },
        {
            date: '2023-04-21',
            title: 'Seminar on Natural language processing with python implementation',
            department: 'Computer Science and Engineering',
            imageSrc: image3,
            isNew: true,
            rating: 5
        },
        // Add more events as needed
    ];

    const chunkEvents = (events, size) => {
        const chunks = [];
        for (let i = 0; i < events.length; i += size) {
            chunks.push(events.slice(i, i + size));
        }
        return chunks;
    };

    const eventChunks = chunkEvents(events, 3); // Change 3 to the number of items you want to show per slide

    return (
        <Carousel>
            {eventChunks.map((chunk, chunkIndex) => (
                <Carousel.Item key={chunkIndex}>
                    <div className="section-title mb-50">
                        <h2>Our Latest News</h2>
                    </div>
                    <div className="d-flex justify-content-around flex-wrap">
                        {chunk.map((event, index) => (
                            <div className="singel-course" key={index}>
                                <div className="thum">
                                    <div className="image" style={{ width: '300px', height: '250px' }}>
                                        <img src={event.imageSrc} alt="" />
                                    </div>
                                    {event.isNew && (
                                        <div className="price">
                                            <span>NEW</span>
                                        </div>
                                    )}
                                </div>
                                <div className="cont">
                                    <ul>
                                        {[...Array(event.rating)].map((_, i) => (
                                            <li key={i}><i className="fa fa-star"></i></li>
                                        ))}
                                    </ul>
                                    <span> ({event.date})</span><br />
                                    <a href="#a" className="course-title"><h5>{event.title}</h5></a>
                                    <div className="course-teacher">
                                        <div className="name">
                                            <a href="#a" className='course-department'><h6>{event.department}</h6></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default LatestEventsCarousel;
