import React from "react";
import { Link } from "react-router-dom";
import "./header.css";
import logo from "../../../assests/images/logo-1.png";
import Navbar from "./navbar";



const Header = () => {
  return (
    <>
      <header>
        <div className="header-top d-flex justify-content-between align-items-center">
          <div className="counselling-code">
            <span>Counselling Code: B.E - 2338</span>
          </div>
          <div className="contact-info">
            <span>Email: acetcoimbatore@gmail.com</span>
            <span>Phone: 0422 2654495</span>
          </div>
        </div>
        <div className="header-buttons d-flex justify-content-between align-items-center">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="header-buttons">
          <Link to="/iqac" className="btn btn-primary" id="naac-button">
              IQAC
            </Link>

            {/* <Nav.Link as={Link} to="/gallery"> */}
            <Link to="/naac-metric" className="btn btn-primary" id="naac-button">
              NAAC
            </Link>
            <Link to="#nirf" className="btn btn-primary" id="nirf-button">
              NIRF
            </Link>
            <Link to="/aicte" className="btn btn-primary" id="aicte-button">
              AICTE Approval Copies
            </Link>
            
            {/* <Button
              href="#mandatory-disclosure"
              id="mandatory-disclosure-button"
            >
              Mandatory Disclosure
            </Button> */}

            <a
              href="pdf/AICTE-MANDATORY-DISCLOSURE.pdf"
              target="_blank"
              rel="noopener noreferrer"
              id="mandatory-disclosure-button"
              download
              className="btn btn-primary"
            >
              Mandatory Disclosure
            </a>
            <Link to="/capacity_building" className="btn btn-primary" id="aicte-button">
            Capacity Building
            </Link>
          </div>
        </div>
<Navbar/>
      </header>
    </>
  );
};

export default Header;
