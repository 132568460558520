import React, { useState, useEffect } from "react";
import Header from "../../../../commons/header/header";
import Footer from "../../../../commons/footer/footer";
import {
  Paper,
  Tabs,
  Tab,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./science.css"; // Ensure you have a CSS file for styling
import image3 from "../../../../../assests/images/mech-bg.jpg";

// TabPanel component to display content for each tab
const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Mech = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="image-section">
        <img src={image3} alt="Department" className="department-image" />
        <div className="overlay-text">SCIENCE AND HUMANITIES</div>
      </div>
      <div className="department-details-content">
        <Paper elevation={3} className="department-details-paper">
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="department tabs"
            centered
          >
            <Tab label="About Department" />
            <Tab label="Faculty Details" />
            <Tab label="PEO / PO / PSO" />
            <Tab label="Lab Facilities" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {/* About Department content */}

            <Typography variant="h6" gutterBottom>
              About Science And Humanities Department
            </Typography>
            <p>
            Spanning disciplines such as mathematics, physics, chemistry, biology, environmental science, and humanities including languages, literature, history, and social sciences, the department provides a comprehensive educational framework. It aims to cultivate a holistic understanding of the natural world and human society, preparing students to tackle global challenges and contribute meaningfully to diverse professional fields. Faculty members within the department are committed to both theoretical exploration and practical application, engaging students in cutting-edge research and hands-on projects. By emphasizing analytical reasoning, ethical responsibility, and creativity, the Department of Science and Humanities cultivates future leaders capable of navigating the complexities of our interconnected world.
            </p>

            <Typography variant="h6" gutterBottom>
              Vision
            </Typography>
            <Typography variant="body1" paragraph>
              To become a reputed centre of learning in Department of S & H and
              transform the students into accomplished professionals.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Mission
            </Typography>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                  To provide a learning ambience to nurture the young minds with
                  theoretical and practical knowledge to produce employable and
                  competent engineers.
                </Typography>
              </li>

              <li>
                <Typography variant="body1" paragraph>
                  To provide a strong foundation in fundamentals of Electronics
                  and Communication Engineering to make students explore
                  advances in research for higher learning.
                </Typography>
              </li>

              <li>
                <Typography variant="body1" paragraph>
                  To inculcate awareness of societal needs, continuous learning
                  and professional practices.
                </Typography>
              </li>

              <li>
                <Typography variant="body1" paragraph>
                  To improve the relationship between industry and academia so
                  that graduates might become inspired revolutionaries in
                  business or leaders in academic.
                </Typography>
              </li>

              <li>
                <Typography variant="body1" paragraph>
                  To imbibe team spirit and leadership qualities among students.
                </Typography>
              </li>
            </ul>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {/* Faculty Details content */}
            <Typography variant="h6" gutterBottom>
              Faculty Details
            </Typography>
            <TableContainer className="table-container">
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-head-cell">S.No.</TableCell>
                    <TableCell className="table-head-cell">
                      Name of the Members
                    </TableCell>
                    <TableCell className="table-head-cell">
                      Designation
                    </TableCell>
                    <TableCell className="table-head-cell">
                      Qualification
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">1</TableCell>
                    <TableCell className="table-cell">Dr. Aejitha</TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc., M.Sc., M.Phil., Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">2</TableCell>
                    <TableCell className="table-cell">
                      Dr. Ramakrishnan A
                    </TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc., M.Sc., Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">3</TableCell>
                    <TableCell className="table-cell">
                      Dr. Russiarani S
                    </TableCell>
                    <TableCell className="table-cell">Professor</TableCell>
                    <TableCell className="table-cell">
                      B.Sc., M.Sc., M.Phil., Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">4</TableCell>
                    <TableCell className="table-cell">Ms. Aparna A V</TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">B.A, M.A</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">5</TableCell>
                    <TableCell className="table-cell">
                      Ms. Kayalvizhi Y
                    </TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.A, M.A, M.Phil
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">6</TableCell>
                    <TableCell className="table-cell">
                      Dr. Govindaraj T
                    </TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc, M.Sc, M.Phil, Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">7</TableCell>
                    <TableCell className="table-cell">Dr. Gowtham B</TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc, M.Sc, M.Phil, Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">8</TableCell>
                    <TableCell className="table-cell">
                      Mr. Faizal Rahman S
                    </TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc, M.Sc, M.Phil
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">9</TableCell>
                    <TableCell className="table-cell">
                      Mrs. Pavithra M
                    </TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc, M.Sc, M.Phil
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">10</TableCell>
                    <TableCell className="table-cell">
                      Dr. Kalyanasundaram M
                    </TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc, M.Sc, M.Phil, Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">11</TableCell>
                    <TableCell className="table-cell">
                      Mr. Karthikeyan R
                    </TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc, M.Sc, M.Phil
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">12</TableCell>
                    <TableCell className="table-cell">
                      Dr. Meenambigai V
                    </TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc, M.Sc, M.Phil, Ph.D
                    </TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">13</TableCell>
                    <TableCell className="table-cell">Ms. Mohana K</TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">
                      B.Sc, M.Sc, M.Phil
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {/* Quality Policy content */}
            <Typography variant="h6" gutterBottom>
              PROGRAM EDUCATIONAL OBJECTIVES (PEOs):
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PEO1</TableCell>
                  <TableCell>
                    Effectuating success in careers by exploring with the
                    design, digital and computational analysis of engineering
                    systems, experimentation and testing, smart manufacturing,
                    technical services, and research.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PEO2</TableCell>
                  <TableCell>
                    Amalgamating effectively with stakeholders to update and
                    improve their core competencies and abilities to ethically
                    compete in the ever-changing multicultural global
                    enterprise.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PEO3</TableCell>
                  <TableCell>
                    To encourage multi-disciplinary research and development to
                    foster advanced technology, and to nurture innovation and
                    entrepreneurship in order to compete successfully in the
                    global economy.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PEO4</TableCell>
                  <TableCell>
                    To globally share and apply technical knowledge to create
                    new opportunities that proactively advances our society
                    through team efforts and to solve various challenging
                    technical, environmental and societal problems.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PEO5</TableCell>
                  <TableCell>
                    To create world class mechanical engineers capable of
                    practice engineering ethically with a solid vision to become
                    great leaders in academia, industries and society.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom>
              PROGRAM OUTCOMES (POs):
            </Typography>
            {/* <Typography variant="h6" gutterBottom>
              Engineering Graduates will be able to:
            </Typography> */}
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PO1</TableCell>
                  <TableCell>
                    <strong>Engineering knowledge:</strong> Apply the knowledge
                    of mathematics, science, engineering fundamentals, and an
                    engineering specialization to the solution of complex
                    engineering problems.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO2</TableCell>
                  <TableCell>
                    <strong>Problem analysis: </strong> Identify, formulate,
                    review research literature, and analyse complex engineering
                    problems reaching substantiated conclusions using first
                    principles of mathematics, natural sciences, and engineering
                    sciences.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO3</TableCell>
                  <TableCell>
                    <strong>Design/development of solutions:</strong> Design
                    solutions for complex engineering problems and design system
                    components or processes that meet the specified needs with
                    appropriate consideration for the public health and safety,
                    and the cultural, societal, and environmental
                    considerations.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO4</TableCell>
                  <TableCell>
                    <strong>Conduct investigations of complex problems:</strong>{" "}
                    Use research-based knowledge and research methods including
                    design of experiments, analysis and interpretation of data,
                    and synthesis of the information to provide valid
                    conclusions.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO5</TableCell>
                  <TableCell>
                    <strong>Modern tool usage:</strong> Create, select, and
                    apply appropriate techniques, resources, and modern
                    engineering and IT tools including prediction and modeling
                    to complex engineering activities with an understanding of
                    the limitations.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO6</TableCell>
                  <TableCell>
                    <strong>The engineer and society:</strong> Apply reasoning
                    informed by the contextual knowledge to assess societal,
                    health, safety, legal and cultural issues and the consequent
                    responsibilities relevant to the professional engineering
                    practice.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO7</TableCell>
                  <TableCell>
                    <strong>Environment and sustainability:</strong> Understand
                    the impact of the professional engineering solutions in
                    societal and environmental contexts, and demonstrate the
                    knowledge of, and need for sustainable development.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO8</TableCell>
                  <TableCell>
                    <strong>Ethics:</strong> Apply ethical principles and commit
                    to professional ethics and responsibilities and norms of the
                    engineering practice.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO9</TableCell>
                  <TableCell>
                    <strong>Individual and team work:</strong>Function
                    effectively as an individual, and as a member or leader in
                    diverse teams, and in multidisciplinary settings.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO10</TableCell>
                  <TableCell>
                    <strong>Communication:</strong> Communicate effectively on
                    complex engineering activities with the engineering
                    community and with society at large, such as, being able to
                    comprehend and write effective reports and design
                    documentation, make effective presentations, and give and
                    receive clear instructions.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO11</TableCell>
                  <TableCell>
                    <strong>Project management and finance:</strong> Demonstrate
                    knowledge and understanding of the engineering and
                    management principles and apply these to one’s own work, as
                    a member and leader in a team, to manage projects and in
                    multidisciplinary environments.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO12</TableCell>
                  <TableCell>
                    <strong>Life-long learning:</strong>Recognize the need for,
                    and have the preparation and ability to engage in
                    independent and life-long learning in the broadest context
                    of technological change.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom>
              PROGRAM SPECIFIC OBJECTIVES (PSOs):
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PSO1</TableCell>
                  <TableCell>
                    To design and develop diagnostic and therapeutic devices
                    that reduces physician burnout and enhance the quality of
                    life for the end user by applying fundamentals of Biomedical
                    Engineering.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PSO2</TableCell>
                  <TableCell>
                    To apply software skills in developing algorithms for
                    solving healthcare related problems in various fields of
                    Medical sector.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PSO3</TableCell>
                  <TableCell>
                    To adapt to emerging information and communication
                    technologies (ICT) to innovate ideas and solutions for
                    current societal and scientific issues thereby developing
                    indigenous medical instruments that are on par with the
                    existing technology.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {/* Lab Facilities content */}
            <Typography variant="h6" gutterBottom>
              Lab Facilities
            </Typography>
            <Typography variant="body1" paragraph>
              1. Data Structures Laboratory
            </Typography>
            <Typography variant="body1" paragraph>
              The Data Structure lab is precise and equipped with high-end Wipro
              and Lenovo desktop machines for data structure laboratory. This
              lab has 34 Intel Core i5 processor machines and Pentium dual-core
              processor machines with 8 GB RAM, 500 HDD, Windows 10 Pro OS,
              exclusively dedicated for faculty members and students to take up
              research and strengthen their knowledge in data structure.
            </Typography>
            <Typography variant="body1" paragraph>
              2. Programming Laboratory
            </Typography>
            <Typography variant="body1" paragraph>
              The lab is designed to enhance the programming proficiency of
              students in languages such as C, Python++, JAVA, and web
              technology utilizing IDEs like Sublime, Visual Basic, and
              Notepad++.
            </Typography>
            <Typography variant="body1" paragraph>
              3. Mobile Application Development
            </Typography>
            <Typography variant="body1" paragraph>
              Our Mobile Application Development Lab, outfitted with Intel Core
              i5 desktop PCs, stands as a vital resource within our facilities.
              It acts as a central hub where students can enhance their skills
              in diverse programming languages like C, C++, Java, Python, and
              database management systems. With 8GB of RAM, the lab is optimized
              for Android application development. Additionally, it accommodates
              various hypervisors such as Oracle Virtual Box, Xen, and
              OpenStack. The lab's infrastructure seamlessly integrates
              high-speed internet connectivity, ensuring students have
              uninterrupted access to online resources.
            </Typography>
            <Typography variant="body1" paragraph>
              4. Data Science & Cloud Computing Laboratory
            </Typography>
            <Typography variant="body1" paragraph>
              The Data Science and Cloud Computing lab is equipped with Samsung
              & Wipro HP desktop machines, designed specifically for data
              analytics and cloud projects. It houses 31 Wipro Intel i5
              machines, each with 16 GB RAM & 5 Samsung, exclusively for faculty
              and student research in Data Science. The lab provides hands-on
              experience, supports international certifications, and helps
              students secure industry jobs.
            </Typography>
          </TabPanel>
        </Paper>
      </div>
      <Footer />
    </>
  );
};

export default Mech;
