import React, { useState, useEffect } from "react";
import Header from "../../../../commons/header/header";
import Footer from "../../../../commons/footer/footer";
import {
  Paper,
  Tabs,
  Tab,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./cse.css"; // Ensure you have a CSS file for styling
import image3 from "../../../../../assests/images/cs-bg.png";

// TabPanel component to display content for each tab
const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Cse = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    // Scroll to the top when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="image-section">
        <img src={image3} alt="Department" className="department-image" />
        <div className="overlay-text">
          B.E. - COMPUTER SCIENCE AND ENGINEERING
        </div>
      </div>

      <div className="department-details-content">
        <Paper elevation={3} className="department-details-paper">
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="department tabs"
            centered
          >
            <Tab label="About Department" />
            <Tab label="Faculty Details" />
            <Tab label="PEO / PO / PSO" />
            <Tab label="Lab Facilities" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {/* About Department content */}

            <Typography variant="h6" gutterBottom>
              About Computer Science And Engineering Department
            </Typography>
            <p>
              Computer Science Engineering (CSE) encompasses a variety of topics
              that relates to computation, like programming languages, analysis
              of algorithms, program design, software, and computer hardware.
              computer engineers have plenty of options to work in IT companies
              in departments.
            </p>

            <p>
              It is a subject that never ceases to fascinate and is a challenge
              for the constant evolution it represents. With the emergence of
              newer and more advances systems, computer science education has
              become more comprehensive than ever. at asian, the department of
              cse, comprising technically-qualified staff and state-of-the art
              labs, continuously strives to equip the students for a career that
              truly clicks!. With the advent of computer systems , few other
              courses have been able to gather as much popularity as cse.
            </p>

            <Typography variant="h6" gutterBottom>
              Vision
            </Typography>
            <Typography variant="body1" paragraph>
            To equip the graduates with the necessary skills to be innovative, self-driven, responsible, and technologically sound citizens who uphold human values. 
            </Typography>
            <Typography variant="h6" gutterBottom>
              Mission
            </Typography>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                To attain academic greatness by giving students in-depth information via efficient pedagogies and practical application of the newest tools and technologies.
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                To carry out multidisciplinary research that will benefit the world community as a whole.
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                To instil in pupils the need for professional skills and ethical responsibility while preparing them to be lifelong learners in a linked world. 
                </Typography>
              </li>
            </ul>
            <ul>
              <li>
                <Typography variant="body1" paragraph>
                To improve the relationship between industry and academia so that graduates might become inspired revolutionaries in business or leaders in academic.
                </Typography>
              </li>
            </ul>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {/* Faculty Details content */}
            <Typography variant="h6" gutterBottom>
              Faculty Details
            </Typography>
            <TableContainer className="table-container">
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-head-cell">S.No.</TableCell>
                    <TableCell className="table-head-cell">
                      Name of the Members
                    </TableCell>
                    <TableCell className="table-head-cell">
                      Designation
                    </TableCell>
                    <TableCell className="table-head-cell">
                      Qualification
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">1</TableCell>
                    <TableCell className="table-cell">
                      Mr. Gowdhaman V
                    </TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">B.Tech, M.E</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">2</TableCell>
                    <TableCell className="table-cell">Mr. Prabhu S</TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">B.E, M.E</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">3</TableCell>
                    <TableCell className="table-cell">Mrs. Princy M</TableCell>
                    <TableCell className="table-cell">
                      Associate Professor
                    </TableCell>
                    <TableCell className="table-cell">B.Tech, M.Tech</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">4</TableCell>
                    <TableCell className="table-cell">
                      Mr. Krishna Kumar S
                    </TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">B.E, M.E</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">5</TableCell>
                    <TableCell className="table-cell">
                      Mr. Murugesan R
                    </TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">B.E, M.E</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">6</TableCell>
                    <TableCell className="table-cell">
                      Mrs. Priyanga M
                    </TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">B.E, M.E</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">7</TableCell>
                    <TableCell className="table-cell">
                      Mrs. Maheswari S
                    </TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">B.E, M.E</TableCell>
                  </TableRow>
                  <TableRow className="table-row">
                    <TableCell className="table-cell">8</TableCell>
                    <TableCell className="table-cell">Ms. Menaka K</TableCell>
                    <TableCell className="table-cell">
                      Assistant Professor
                    </TableCell>
                    <TableCell className="table-cell">B.E, M.E</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Typography variant="h6" gutterBottom>
              PROGRAM EDUCATIONAL OBJECTIVES (PEOs):
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PEO1</TableCell>
                  <TableCell>
                    Apply their technical competence in computer science to
                    solve real world problems, with technical and people
                    leadership.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PEO2</TableCell>
                  <TableCell>
                    Conduct cutting edge research and develop solutions on
                    problems of social relevance.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PEO3</TableCell>
                  <TableCell>
                    Work in a business environment, exhibiting team skills, work
                    ethics, adaptability and lifelong learning.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom>
              PROGRAM OUTCOMES (POs):
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PO1</TableCell>
                  <TableCell>
                    <strong>Engineering knowledge:</strong> Apply the knowledge
                    of mathematics, science, engineering fundamentals and an
                    engineering specialization to the solution of complex
                    engineering problems.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO2</TableCell>
                  <TableCell>
                    <strong>Problem analysis: </strong> Identify, formulate,
                    review research literature, and analyze complex engineering
                    problems reaching substantiated conclusions using first
                    principles of mathematics, natural sciences, and engineering
                    sciences.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO3</TableCell>
                  <TableCell>
                    <strong>Design/development of solutions:</strong>Design
                    solutions for complex engineering problems and design system
                    components or processes that meet the specified needs with
                    appropriate consideration for the public health and safety,
                    and the cultural, societal, and environmental
                    considerations.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO4</TableCell>
                  <TableCell>
                    <strong>Conduct investigations of complex problems:</strong>{" "}
                    Use research-based knowledge and research methods including
                    design of experiments, analysis and interpretation of data,
                    and synthesis of the information to provide valid
                    conclusions.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO5</TableCell>
                  <TableCell>
                    <strong>Modern tool usage:</strong>Create, select, and apply
                    appropriate techniques, resources, and modern engineering
                    and IT tools including prediction and modeling to complex
                    engineering activities with an understanding of the
                    limitations.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO6</TableCell>
                  <TableCell>
                    <strong>The engineer and society:</strong> Apply reasoning
                    informed by the contextual knowledge to assess societal,
                    health, safety, legal and cultural issues and the consequent
                    responsibilities relevant to the professional engineering
                    practice.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO7</TableCell>
                  <TableCell>
                    <strong>Environment and sustainability:</strong> Understand
                    the impact of the professional engineering solutions in
                    societal and environmental contexts, and demonstrate the
                    knowledge of, and need for sustainable development.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO8</TableCell>
                  <TableCell>
                    <strong>Ethics:</strong> Apply ethical principles and commit
                    to professional ethics and responsibilities and norms of the
                    engineering practice.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO9</TableCell>
                  <TableCell>
                    <strong>Individual and team work:</strong>Function
                    effectively as an individual, and as a member or leader in
                    diverse teams, and in multidisciplinary settings.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO10</TableCell>
                  <TableCell>
                    <strong>Communication:</strong>Communicate effectively on
                    complex engineering activities with the engineering
                    community and with society at large, such as, being able to
                    comprehend and write effective reports and design
                    documentation, make effective presentations, and give and
                    receive clear instructions.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO11</TableCell>
                  <TableCell>
                    <strong>Project management and finance:</strong>Demonstrate
                    knowledge and understanding of the engineering and
                    management principles and apply these to one’s own work, as
                    a member and leader in a team, to manage projects and in
                    multidisciplinary environments.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PO12</TableCell>
                  <TableCell>
                    <strong>Life-long learning:</strong>Recognize the need for,
                    and have the preparation and ability to engage in
                    independent and life-long learning in the broadest context
                    of technological change.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom>
              PROGRAM SPECIFIC OBJECTIVES (PSOs):
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>PSO1</TableCell>
                  <TableCell>
                    Exhibit design and programming skills to build and automate
                    business solutions using cutting edge technologies.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PSO2</TableCell>
                  <TableCell>
                    Strong theoretical foundation leading to excellence and
                    excitement towards research, to provide elegant solutions to
                    complex problems.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PSO3</TableCell>
                  <TableCell>
                    Ability to work effectively with various engineering fields
                    as a team to design, build and develop system applications.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {/* Lab Facilities content */}
            <Typography variant="h6" gutterBottom>
              Lab Facilities
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>1. Data Structures Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The Data Structure lab is precise and equipped with high-end Wipro
              and Lenovo desktop machines for data structure laboratory. This
              lab has 34 Intel Core i5 processor machines and Pentium dual-core
              processor machines with 8 GB RAM, 500 HDD, Windows 10 Pro OS,
              exclusively dedicated for faculty members and students to take up
              research and strengthen their knowledge in data structure.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>2. Programming Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The lab is designed to enhance the programming proficiency of
              students in languages such as C, Python++, JAVA, and web
              technology utilizing IDEs like Sublime, Visual Basic, and
              Notepad++.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>3. Mobile Application Development</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              Our Mobile Application Development Lab, outfitted with Intel Core
              i5 desktop PCs, stands as a vital resource within our facilities.
              It acts as a central hub where students can enhance their skills
              in diverse programming languages like C, C++, Java, Python, and
              database management systems. With 8GB of RAM, the lab is optimized
              for Android application development. Additionally, it accommodates
              various hypervisors such as Oracle Virtual Box, Xen, and
              OpenStack. The lab's infrastructure seamlessly integrates
              high-speed internet connectivity, ensuring students have
              uninterrupted access to online resources.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>4. Data Science & Cloud Computing Laboratory</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              The Data Science and Cloud Computing lab is equipped with Samsung
              & Wipro HP desktop machines, designed specifically for data
              analytics and cloud projects. It houses 31 Wipro Intel i5
              machines, each with 16 GB RAM & 5 Samsung, exclusively for faculty
              and student research in Data Science. The lab provides hands-on
              experience, supports international certifications, and helps
              students secure industry jobs.
            </Typography>
          </TabPanel>
        </Paper>
      </div>
      <Footer />
    </>
  );
};

export default Cse;
