import React from "react";
import { Box, Typography } from "@mui/material";
import "./notification.css"; // Import CSS for styling

const Notification = () => {
  return (
    <Box className="marquee-container">
      <Typography variant="h6" component="div" className="marquee-content">
        Admission Open For 2024 - 2025 Batch. Scholarship Available For Meritorious and Deserving Students. &nbsp;&nbsp;&nbsp; {/* Adding spaces */}
        For Admission Contact: Mr. Babu Senthilkumar (A.O): 9994339956, 8754955999 | Mr. Prabu (C.E.O): 8973505785 | Mr. Arjun (P.R.O): 9600402530
      </Typography>
    </Box>
  );
};

export default Notification;
