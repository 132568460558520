import React from "react";
import Header from "../../commons/header/header";
import Footer from "../../commons/footer/footer";
import "./about.css"; // Ensure you have a CSS file for styling
import AboutVideo from "../../../assests/video/acet.mp4";

const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="aboutus-container">
        <section className="management">
          <h2>Management</h2>
          <p>
            Asian College of Engineering and Technology is promoted by the
            Krishna Charitable Trust, Mettupalayam. The Trust was established in
            the year 2011 and is registered with Income Tax Authorities under
            Section 12 of the Income Tax Act.
          </p>
          <p>
            The Trust was established with an objective of establishing
            educational institutions in various fields like Engineering,
            Medical, Paramedical, Nursing, Dental, Electronics, Communications,
            Computer Applications, Technical, Academic, Arts and Science,
            Business and Management, Vocational, Sports and Games, Teacher
            training, and such other Educational fields. The trust members have
            rich experience in the area of academics, industry, business, and
            agriculture.
          </p>
        </section>

        <section className="vision-mission">
          <h2>Vision of the Institution</h2>
          <p>
          To provide quality technical education and research to cater the needs of the industry and society and mould the character of younger generation to achieve excellence through wholesome learning.
          </p>

          <h2>Mission of the Institution</h2>
          <ul>
            <li>To Establish collaboration with the industries.</li>
            <li>To Create centre of excellence in promoting research in the areas of water, Energy and Environment Foster ethics and values among younger generation.</li>
          </ul>
        </section>

        <section className="quality-policy">
          <h2>Quality Policy</h2>
          <p>
            To follow global standards of excellence in teaching and research
            and to remain accountable in our core and support functions, through
            processes of self-evaluation and continuous improvement.
          </p>
        </section>

        <section className="aboutus-video">
          <h2>Watch Video</h2>
          <video controls className="responsive-video" preload="metadata">
            <source src={AboutVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
