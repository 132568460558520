import React, { useState } from "react";
import Header from "../../../commons/header/header";
import Footer from "../../../commons/footer/footer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
} from "@mui/material";
import "../committee.css"; // Import the CSS file

const ICC = () => {
  const [selectedTab, setSelectedTab] = useState("committee");

  const rows = [
    {
      id: 1,
      name: "Dr. Savithri K.M",
      designation: "Vice Principal",
      category: "Vice Principal",
    },
    {
      id: 2,
      name: "Mrs. Renugadevi",
      designation: "HOD/EEE",
      category: "Chairman",
    },
    {
      id: 3,
      name: "Ms.Padamavathi ",
      designation: "NGO",
      category: "Member",
    },
    {
      id: 4,
      name: "Dr. Radhika",
      designation: "Joint Managing Director, SMF Emergency Hospital, Annur, Coimbatore",
      category: "External Member",
    },
    {
      id: 5,
      name: "Dr. Aejitha S",
      designation: "HOD/S&H",
      category: "Member",
    },
    { id: 6, name: "Mrs. Mageswari", designation: "HOD/CSE", category: "Member" },
    {
      id: 7,
      name: "Dr. Nancy Priya",
      designation:
        "HOD/AGRI",
      category: "Member",
    },
    {
      id: 8,
      name: "Ms. Lalitha M",
      designation: "Student (II BME)",
      category: "Student Representative 1",
    },
    {
      id: 9,
      name: "Ms. Logadharshini R",
      designation: "Student (III CSE)",
      category: "Student Representative 2",
    },
    // {
    //   id: 10,
    //   name: "Ms. Logadharshini R",
    //   designation: "Student (III CSE)",
    //   category: "Student Representative 2",
    // },
  ];

  const handlePDFClick = () => {
    window.open(
      `${process.env.PUBLIC_URL}/pdf/icc_minutes_of_meeting.pdf`,
      "_blank"
    );
  };
  return (
    <>
      <Header />
      <div className="antiragging-container">
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          width="100%"
        >
          {/* Left side menu */}
          <Box
            flex={{ xs: "none", sm: "0 0 200px" }}
            bgcolor="transparent"
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap={1} // Add spacing between buttons
            sx={{
              // Responsive styling
              "@media (max-width: 600px)": {
                flex: "0 0 auto",
              },
              "@media (min-width: 600px)": {
                flex: "0 0 200px",
              },
            }}
          >
            <Button
              variant={selectedTab === "committee" ? "contained" : "outlined"}
              onClick={() => setSelectedTab("committee")}
              style={{
                width: "100%",
                color: selectedTab === "committee" ? "#004d40" : "#000",
                borderColor:
                  selectedTab === "committee" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              Internal Complaint Committee
            </Button>
            <Button
              variant={selectedTab === "mom" ? "contained" : "outlined"}
              onClick={handlePDFClick}
              style={{
                width: "100%",
                color: selectedTab === "mom" ? "#004d40" : "#000",
                borderColor: selectedTab === "mom" ? "#004d40" : "#80deea",
                borderWidth: "2px",
              }}
            >
              Minutes Of Meeting
            </Button>
          </Box>

          {/* Right side content */}
          <Box flex="1" p={2}>
            <h2>
              {selectedTab === "committee"
                ? "Internal Complaint Committee"
                : "mom"}
            </h2>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      Name of the Members
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      Designation
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#01366b", color: "white" }}
                    >
                      Category
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(selectedTab === "committee"
                    ? rows
                    : selectedTab === "mom"
                  ).map((row, index) => (
                    <TableRow
                      key={row.id}
                      style={{
                        backgroundColor: index % 2 === 0 ? "white" : "#f1f8e9",
                      }}
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.designation}</TableCell>
                      <TableCell>{row.category}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default ICC;
